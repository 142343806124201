import config from "../../src/configs/config";
const languages: any = {
  fa: {
    language: "فارسی",
    short: "fa",
    rtl: true,
    correct: "تصحیح",
    confrimationCode: "کد تایید",
    confirmationCodePlaceHolder: "XXXXXX",
    account: "حساب",
    mainAccount: "حساب اصلی",
    continue: "ادامه",
    charge: "شارژ",
    dollar: "دلار",
    back: "بازگشت",
    toman: config.mainAccountUnit === "Toman" ? "تومان" : "ریال",
    destinataion: "مقصد",
    moneyTransfer: "انتقال وجه",
    transactoinDiscription: "توضیح تراکنش",
    insertTransactionDescriptionHere: "توضیح تراکنش را اینجا وارد کنید",
    description: "توضیحات",
    depositeFrom: "واریز از",
    deposite: "واریز",
    transfer: "انتقال",
    username: "نام کاربری",
    password: "رمز عبور",
    amount: "مبلغ",
    remaining: "مانده",
    balance: "موجودی",
    withdraw: "برداشت",
    withDrawFromAccount: "برداشت از حساب",
    chargeAccount: "شارژ حساب کاربری",
    transferTo: "انتقال به",
    transferFrom: "واریز از",
    refund: "استرداد",
    captcha: "کد امنیتی",
    signin: "ورود",
    signout: "خروج",
    register: "ثبت نام",
    loginUserNameTitle: "شماره تلفن همراه یا ایمیل خود را وارد نمایید.",
    loginUserNamePlaceHolder: "تلفن همراه یا ایمیل",
    loginPasswordTitle: "رمز عبور",
    loginPasswordPlaceHolder: "رمز عبور خود را وارد نمایید",
    dynamicWords: {
      tomanAccountName: "حساب اصلی",
      tomanAccountUnit: config.mainAccountUnit === "Toman" ? "تومان" : "ریال",
      dollarAccountName: "حساب دلاری",
      dollarAccountUnit: "دلار",
      euroAccountName: "حساب یورو",
      euroAccountUnit: "یورو",
      dirhamAccountName: "حساب درهم",
      dirhamAccountUnit: "درهم",
    },
    destinationUsername: "نام کاربری مقصد",
    confirmationOfMoneyTransfer: "تایید انتقال وجه",
    confirmation: "تایید",
    disapproval: "عدم تایید",
    edit: "ویرایش",
    withdrawLaws: `
            <p>لطفا پیش از برداشت به نکات زیر توجه فرمایید:</p>
            <ul class="list-disc p-1 m-4 mt-1 mb-3">
              <li>
                برای برداشت اطلاعات هویتی شما پیش از این باید ثبت شده باشد.
              </li>
              <li>برای ثبت اطلاعات هویتی از این لینک استفاده کنید.</li>
            </ul>
    `,
    fullname: "نام و نام خانوادگی",
    iban: "شماره شبا",
    paymentId: "شناسه پرداخت",
    insertUserName: "نام کاربری را وارد نمایید",
    WithdrawLaws1: "کاربر گرامی پیش از تایید لطفا به موارد زیر توجه نمایید:",
    WithdrawLaws2:
      "بر اساس رویه جدید بانکی بر روی تراکنش های به مقصد غیر از بانک کارآفرین کارمزد اعمال خواهد شد.",
    withdrawLaws3Head: "مبلغ کارمزد",
    withdrawLaws4Head: "مبلغی که از حساب شما کسر خواهد شد با احتساب کارمزد",
    withdrawLaws4Tail: "تومان می باشد",
    contact: {
      s1: "مخاطبین",
      s2: "نام مخاطب",
      s3: "ردیف",
      s4: "مخاطب مورد نظر در این سایت حساب ندارد. پس از ثبت نام کاربر اطلاعات ایشان نمایش داده خواهد شد.",
      s5: "شما نمی توانید حساب کاربری خودتان را اضافه نمایید.",
      s6: "با موفقیت ثبت نام شد.",
      s7: "شما نمی توانید حساب کاربری خودتان را اضافه نمایید.",
      s8: "این کاربر پیش از این ثبت شده است.",
      s9: "ایمیل یا شماره تلفن همراه مخاطب صحیح نیست.",
      s10: "افزودن مخاطب",
      s11: "نام مخاطب نمی تواند خالی باشد.",
      s12: "ثبت",
      s13: "ردیف",
      s14: "اطلاعات تماس",
      s15: "عملیات",
      s17: "مخاطب حذف شود؟",
      s18: "موبایل یا ایمیل",
    },
    bankAccount: {
      s1: "شماره شبا صحیح نیست",
      s2: "نام خود صاحب حساب را وارد نمایید",
      s3: "با موفقیت انجام شد",
      s4: "این حساب بانکی پیش از این ثبت شده است",
      s5: "این شماره شبا صحیح نیست",
      s6: "افزودن حساب مخاطب",
      s7: "نام صاحب حساب",
      s8: "شماره شبا",
      s9: "حساب های بانکی",
      s10: "ثبت",
      s11: "امکان برداشت تا",
      s12: "میلیون تومان",
      s13: "آیا از حذف مطمئن هستید؟",
      s14: "بله",
      s15: "خیر",
    },
    menu: {
      s1: "خانه",
      s2: "مخاطبین",
      s3: "حساب های بانکی",
      s4: "آپلود مدارک",
      s5: "پین کد",
    },
    chargePage: {
      s1: "شارژ کیف پول",
      s2: "پرداخت آنلاین",
      s3: "واریز با شناسه",
      s4: "حداقل میزان شارژ مبلغ ده هزار تومان می باشد.",
      s5: "در حال حاضر امکان شارژ حساب ",
      s6: "وجود ندارد.",
      s7: `حداقل واریز با شناسه نمی تواند کمتر از ۵,۰۰۰ تومان باشد.`,
      s8: "لطفا به بانک مراجعه نمایید و مبلغ ",
      s9: "تومان با شناسه واریز ",
      s10: "به حساب 0106170615004 به نام<<مرکز تحقیقات علوم و فناوری‌های هوشمند کوثر دانشگاه تهران>> واریز نمایید.",
      s11: "مبلغ شارژ",
    },
    loginPage: {
      s1: "ورود | ثبت نام",
      s2: "ورود شما به معنای پذیریش قوانین حریم خصوصی است",

      s7: "کد تایید را وارد کنید",
      s8: " کد تایید برای شماره ",
      s9: "پیامک شد",
      s10: "مانده تا دریافت مجدد کد",
      s11: "شماره موبایل یا ایمیل را وارد نمایید.",
      s12: "کد تایید صحیح نیست",
      s13: "کد امنیتی اشتباه است",
      s14: "و",
      s15: "مرا به خاطر بسپار",
    },
    pageTransfer: {
      s1: "نام کاربری را وارد نمایید.",
      s2: "مبلغ را وارد نمایید.",
      s3: "پین کد درست نمی باشد",
      s4: "موجودی کافی نیست",
      s5: "اطلاعات تراکنش",
      s6: "تراکنش با موفقیت انجام شد.",
      s7: "مبلغ انتقال",
    },
    pageWithdraw: {
      s1: "مبلغ برداشت را وارد نمایید.",
      s2: "شماره شبا را وارد نمایید.",
      s3: "نام و نام خانوادگی را وارد نمایید.",
      s4: "پین کد معتبر نیست",
      s5: "برای برخورداری از امکان برداشت لازم است اطلاعات و مدارک خود را بارگذاری نمایید.",
      s6: "موجودی شما کافی نیست",
      s7: "حداقل مقدار برداشت مبلغ ۱۰ هزار تومان می باشد.",
      s8: "در حال حاضر این امکان وجود ندارد.",
      s9: "پرداخت با موفقیت انجام شد.",
      s10: "شماره شبا صحیح نیست.",
      s11: "مبلغ برداشت",
    },
    pagePinCode: {
      s1: "طول پین کد باید ۵ کاراکتر باشد.",
      s2: "پین کد با موفقیت ثبت شد",
      s3: "رمز عبور صحیح نیست",
      s4: "ثبت پین کد",
      s5: "رمز عبور فعلی",
      s6: "پین کد دلخواه",
      s7: "ثبت",
    },
    uploadPage: {
      s1: "بارگذاری تصویر روی کارت ملی",
      s2: "برای بارگذاری تصویر روی کارت ملی اینجا کلیک نمایید یا تصویر خود را اینجا رها نمایید.",
      s3: "خطا حجم عکس کمتر از ۳۰۰ کیلوبایت باشد.",
      s4: "در صورتی که اتباع هستید اینجا کلیک نمایید",
      s5: "بارگذاری تصویر پشت کارت ملی",
      s6: "برای بارگذاری تصویر پشت کارت ملی اینجا کلیک نمایید یا تصویر خود را اینجا رها کنید.",
      s7: "بارگذاری تصویر شناسنامه",
      s8: "برای بارگذاری تصویر صفحه اول شناسنامه اینجا کلیک کنید یا تصویر خود را اینجا رها کنید.",
      s9: "بارگذاری تصویر گذرنامه",
      s10: "برای بارگذاری تصویر گذرنامه اینجا کلیک کنید یا تصویر خود را اینجا رها کنید.",
      s11: "بارگذاری کارت اتباع",
      s12: "برای بارگذاری تصویر کارت اتباع اینجا کلیک کنید یا تصویر خود را اینجا رها کنید.",
      s13: "در صورتی که ایرانی هستید اینجا کلیک نمایید",
      s14: "بارگذاری کارت اتباع",
      s15: "برای بارگذاری تصویر کارت اتباع اینجا کلیک کنید یا تصویر خود را اینجا رها کنید.",
      s16: "در صورتی که ایرانی هستید اینجا کلیک نمایید",
      s17: "بارگذاری گذرنامه اتباع",
      s18: "برای بارگذاری تصویر کارت اتباع اینجا کلیک کنید یا تصویر خود را اینجا رها کنید.",
      s19: "بارگذاری اساسنامه",
      s20: "برای بارگذاری تصویر اساسنامه اینجا کلیک کنید یا تصویر خود را اینجا رها کنید.",
      s21: "آگهی تاسیس کنندگان",
      s22: "برای بارگذاری آگهی تاسیس کنندگان اینجا کلیک کنید یا تصویر خود را اینجا رها کنید.",
      s23: "بارگذاری اسامی سهامداران",
      s24: "برای بارگذاری تصویر اسامی سهامداران اینجا کلیک کنید یا تصویر خود را اینجا رها کنید.",
      s25: "آگهی امضا داران",
      s26: "برای بارگذاری تصویر روزنامه اینجا کلیک کنید یا تصویر خود را اینجا رها کنید.",
      s27: "کد ملی",
      s28: "تاریخ تولد",
      s29: "نام",
      s30: "نام خانوادگی",
      s31: "نام پدر",
      s32: "تایید",
      s33: "بازگشت",
    },
  },
  en: {
    correctPhoneNumber: "edit",
    confrimationCode: "confirmation code",
    confirmationCodePlaceHolder: "XXXXXX",
    language: "English",
    short: "en",
    account: "Account",
    mainAccount: "Main Account",
    continue: "Continue",
    charge: "Charge",
    dollar: "Dollar",
    back: "Back",
    toman: config.mainAccountUnit === "Toman" ? "Toman" : "Rial",

    destinataion: "Destinataion",
    moneyTransfer: "Money Transfer",
    transactoinDescription: "Transactoin Description",
    insertTransactionDescriptionHere: "Insert Transaction Description Here",
    description: "description",
    depositeFrom: "Deposite From",
    deposite: "Deposite",
    transfer: "Transfer",
    username: "username",
    password: "password",
    amount: "Amount",
    remaining: "Remaining",
    balance: "Balance",
    withdraw: "Withdraw",
    withDrawFromAccount: "Withdraw",
    chargeAccount: "Charging account",
    transferTo: "Transfer to",
    transferFrom: "Transfer from",
    refund: "Refund",
    captcha: "Captcha",
    signin: "signin",
    signout: "signout",
    register: "register",
    loginUserNameTitle: "Please insert your phone number or email",
    loginUserNamePlaceHolder: "phone number or email",
    loginPasswordTitle: "Password",
    loginPasswordPlaceHolder: "password",
    dynamicWords: {
      tomanAccountName: "Toman Account",
      tomanAccountUnit: config.mainAccountUnit === "Toman" ? "Toman" : "Rial",
      dollarAccountName: "Dollar Account",
      dollarAccountUnit: "Dollar",
      euroAccountName: "Euro Account",
      euroAccountUnit: "Euro",
      dirhamAccountName: "Dirham Account",
      dirhamAccountUnit: "Dirham",
    },
    destinationUsername: "Destination username",
    confirmationOfMoneyTransfer: "Confirmation of money transfer",
    confirmation: "confirm",
    disapproval: "Disapproval",
    edit: "edit",
    withdrawLaws: `
    <p>Please pay attention to the following points before withdrawing:</p>
    <ul class="list-disc p-1 m-4 mt-1 mb-3">
      <li>
        To withdraw, your identity information must be registered before.
      </li>
      <li>Use this link to register identity information.</li>
    </ul>
`,
    fullname: "name and family",
    iban: "iban number",
    paymentId: "Payment id",
    insertUserName: "Insert username",
    WithdrawLaws1:
      "Dear user, before confirming, please pay attention to the following:",
    WithdrawLaws2:
      "According to the new banking procedure, a fee will be applied to transactions to destinations other than the Entrepreneur Bank.",
    withdrawLaws3Head: "fee amount",
    withdrawLaws4Head: "The amount that will be deducted from your account is",
    withdrawLaws4Tail: "Toman including the fee.",
    contact: {
      s1: "contacts",
      s2: "Your entry signifies your acceptance of the privacy policy",
      s5: "You cannot add your account.",
      s6: "Registered successfully.",
      s7: "You cannot add your account.",
      s8: "This user is already registered.",
      s9: "The contact's email or mobile phone number is not correct.",
      s10: "Add contact",
      s11: "Contact name cannot be empty.",
      s12: "registration",
      s13: "row",
      s14: "name",
      s15: "Operation",
      s17: "Remove contact?",
      s18: "mobile or phone",
    },
    bankAccount: {
      s1: "Shaba number is not correct",
      s2: "Enter the account owner's name",
      s3: "Successfully done",
      s4: "This bank account is already registered",
      s5: "This Shaba number is not correct",
      s6: "Add contact account",
      s7: "name of account holder",
      s8: "Sheba number",
      s9: "bank accounts",
      s10: "registration",
      s11: "Possibility to pick up",
      s12: "Million Tomans",
    },
    menu: {
      s1: "home",
      s2: "contacts",
      s3: "bank accounts",
      s4: "upload docs",
      s5: "pin code",
    },
    chargePage: {
      s1: "charge wallet",
      s2: "online payment",
      s3: "Deposit with ID",
      s4: "The minimum charge amount is ten thousand tomans.",
      s5: "It is now possible to top up the account",
      s6: "None.",
      s7: "The minimum deposit with ID cannot be less than 5,000 Tomans.",
      s8: "Please refer to the bank and the amount",
      s9: "Toman with deposit ID",
      s10: "Deposit to account 0106170615004 under the name of Kowsar Research Center for Smart Sciences and Technologies of Tehran University",
      s11: "Charge amount",
    },
    loginPage: {
      s1: "Login | Register",
      s2: "Your entry means acceptance",
      s3: "Manisean conditions",
      s4: "Privacy Rules",
      s5: "is",
      s7: "Enter verification code",
      s8: "verification code for number",
      s9: "sent",
      s10: "remaining until receiving the code again",
      s11: "Enter the mobile number or email.",
      s12: "verification code is not valid",
      s13: "The security code is wrong",
      s14: "and",
      s15: "remember me",
    },
    pageTransfer: {
      s1: "Enter username.",
      s2: "Enter the amount.",
      s3: "PIN code is not correct",
      s4: "Not enough stock",
      s5: "Transaction Information",
      s6: "Transaction completed successfully.",
      s7: "Transfer Amount",
    },
    pageWithdraw: {
      s1: "Enter the withdrawal amount.",
      s2: "Enter Shaba's number.",
      s3: "Enter first and last name.",
      s4: "PIN is not valid",
      s5: "In order to be able to withdraw, it is necessary to upload your information and documents.",
      s6: "Your balance is not enough",
      s7: "The minimum withdrawal amount is 10 thousand tomans.",
      s8: "This is not possible at this time.",
      s9: "Payment successfully completed.",
      s10: "Shaba's number is not correct.",
      s11: "Withdraw amount",
    },
    pagePinCode: {
      s1: "The length of the PIN code must be 5 characters.",
      s2: "PIN code registered successfully",
      s3: "The password is not correct",
      s4: "Pin code registration",
      s5: "current password",
      s6: "custom pin code",
      s7: "register",
    },
    uploadPage: {
      s1: "uploading the image on the national card",
      s2: "Click here to upload the picture on the national card or leave your picture here.",
      s3: "The error is that the image size is less than 300 KB.",
      s4: "Click here if you are a citizen",
      s5: "uploading the image behind the national card",
      s6: "Click here to upload the picture on the back of the national card or leave your picture here.",
      s7: "Uploading ID card image",
      s8: "Click here to upload the image of the first page of the birth certificate or leave your image here.",
      s9: "upload passport image",
      s10: "Click here to upload your passport image or drop your image here.",
      s11: "Uploading Citizens Card",
      s12: "Click here to upload your national card image or drop your image here.",
      s13: "Click here if you are Iranian",
      s14: "Uploading Citizens Card",
      s15: "Click here to upload your national card image or drop your image here.",
      s16: "Click here if you are Iranian",
      s17: "Uploading nationals' passports",
      s18: "Click here to upload a picture of the national card or drop your picture here.",
      s19: "loading statute",
      s20: "Click here to upload an image of the statute or drop your image here.",
      s21: "Founders' Notice",
      s22: "Click here to upload founders ad or drop your image here.",
      s23: "Loading shareholder names",
      s24: "Click here to upload an image of shareholder names or drop your image here.",
      s25: "Notice of Signatories",
      s26: "Click here to upload a newspaper image or drop your image here.",
      s27: "National Code",
      s28: "Date of Birth",
      s29: "name",
      s30: "surname",
      s31: "Father's Name",
      s32: "Confirm",
      s33: "Return",
    },
  },

  ar: {
    language: "العربية",
    short: "ar",
    rtl: true,
    correct: "تصحیح",
    confrimationCode: "رمز التحقق",
    confirmationCodePlaceHolder: "XXXXXX",
    account: "الحساب",
    mainAccount: "الحساب الاصلي",
    continue: "التالي",
    charge: "شحن",
    dollar: "دولار",
    back: "الرجوع",
    toman: "ريال",
    destinataion: "الوجهة",
    moneyTransfer: "تحويل المال",
    transactoinDiscription: "وصف العملية",
    insertTransactionDescriptionHere: "الرجاء ادخال وصف العملية",
    description: "الوصف",
    depositeFrom: "الايداع من",
    deposite: "ايداع",
    transfer: "تحويل",
    username: "اسم المستخدم",
    password: "كلمة المرور",
    amount: "المبلغ",
    remaining: "المتبقي",
    balance: "الرصيد",
    withdraw: "سحب",
    withDrawFromAccount: "سحب من الحساب",
    chargeAccount: "شحن حساب المستخدم",
    transferTo: "تحويل الى",
    transferFrom: "تحويل من",
    refund: "استرداد",
    captcha: "کود التحقق",
    signin: "تسجيل الدخول",
    signout: "تسجيل الخروج",
    register: "إنشاء حساب",
    loginUserNameTitle: "ادخل رقم تلفونك او بريدك الالكتروني",
    loginUserNamePlaceHolder: "رقم تلفون او بريد الكتروني",
    loginPasswordTitle: "كلمة المرور",
    loginPasswordPlaceHolder: "ادخل كلمة المرور",
    dynamicWords: {
      tomanAccountName: "الحساب الاصلي",
      tomanAccountUnit: "ريال",
      dollarAccountName: "حساب الدولار",
      dollarAccountUnit: "دولار",
      euroAccountName: "حساب الیورو",
      euroAccountUnit: "یورو",
      dirhamAccountName: "حساب الدرهم",
      dirhamAccountUnit: "درهم",
    },
    destinationUsername: "اسم مستخدم المحول له",
    confirmationOfMoneyTransfer: "تأكيد عملية التحويل",
    confirmation: "تأكيد",
    disapproval: "الغاء",
    edit: "تعديل",
    withdrawLaws: `
            <p>يرجى الانتباه إلى النقاط التالية قبل السحب:</p>
            <ul class="list-disc p-1 m-4 mt-1 mb-3">
              <li>
                يجب ان تكون معلومات الهوية مسجلة من قبل.
              </li>
              <li>استخدم هذا الرابط لتسجيل معلومات الهوية.</li>
            </ul>
    `,
    fullname: "الاسم واللقب",
    iban: "رقم الايبان",
    paymentId: "معرف الدفع",
    insertUserName: "ادخل اسم المستخدم",
    WithdrawLaws1:
      "عزيزي المستخدم، يرجى الانتباه إلى النقاط التالية قبل التأكيد:",
    WithdrawLaws2:
      "وفقًا للإجراء المصرفي الجديد، سيتم تطبيق رسوم على المعاملات إلى الوجهات الأخرى غير بنك رواد الأعمال.",
    withdrawLaws3Head: "مبلغ الرسوم",
    withdrawLaws4Head: "المبلغ الذي سيتم خصمه من حسابك بما في ذلك الرسوم",
    withdrawLaws4Tail: "بالريال",
    contact: {
      s1: "جهات الاتصال",
      s2: "دخولك يدل على موافقتك على سياسة الخصوصية",
      s3: "صف",
      s4: "جهة الاتصال المقصود ليس لديه حساب في هذا الموقع. بعد قيام المستخدم بالتسجيل، سيتم عرض المعلومات الخاصة به.",
      s5: "لا يمكنك إضافة حسابك الخاص.",
      s6: "تم التسجيل بنجاح.",
      s7: "لا يمكنك إضافة حسابك الخاص.",
      s8: "هذا المستخدم مسجل بالفعل.",
      s9: "البريد الإلكتروني أو رقم الهاتف المحمول لجهة الاتصال غير صحيح.",
      s10: "إضافة جهة اتصال",
      s11: "لا يمكن أن يكون اسم جهة الاتصال فارغًا.",
      s12: "تسجيل",
      s13: "صف",
      s14: "معلومات الاتصال",
      s15: "عملیات",
      s17: "هل تريد حذف جهة الاتصال؟",
      s18: "رقم التلفون او البريد الالكتروني",
    },
    bankAccount: {
      s1: "رقم الايبان غير صحيح",
      s2: "أدخل اسم صاحب الحساب",
      s3: "تمت بنجاح",
      s4: "هذا الحساب البنكي مسجل بالفعل",
      s5: "رقم الايبان هذا غير صحيح",
      s6: "إضافة حساب جهة اتصال",
      s7: "اسم صاحب حساب",
      s8: "رقم الايبان",
      s9: "حسابات بنكية",
      s10: "تسجيل",
      s11: "امكانية الالتقاط",
      s12: "عشرة الف ريال",
      s13: "هل أنت متأكد تريد الحذف؟",
      s14: "نعم",
      s15: "الغاء",
    },
    menu: {
      s1: "الرئيسية",
      s2: "جهات الاتصال",
      s3: "الحسابات البنكية",
      s4: "تحميل المستندات",
      s5: "الرمز السري",
    },
    chargePage: {
      s1: "شحن المحفظة",
      s2: "الدفع الالكتروني",
      s3: "الايداع مع البطاقة",
      s4: "الحد الأدنى لمبلغ الرسوم هو الف ريال.",
      s5: "أصبح من الممكن الآن إعادة شحن الحساب",
      s6: "غير موجود",
      s7: `لا يمكن أن يكون الحد الأدنى للإيداع ببطاقة الهوية أقل من 500 ريال.`,
      s8: "يرجى الرجوع إلى البنك والمبلغ",
      s9: "ريال مع معرف الإيداع ",
      s10: "الإيداع في الحساب رقم **************** باسم <<*****************>>.",
      s11: "مبلغ الشحن",
    },
    loginPage: {
      s1: "تسجيل الدخول | انشاء مستخدم",
      s2: "دخولك يدل على موافقتك على سياسة الخصوصية",

      s7: "ادخل رمز التحقق",
      s8: " رمز التحقق للرقم ",
      s9: "لقد تم إرسال رسالة نصية",
      s10: "المتبقي لتلقي الرمز مرة أخرى",
      s11: "أدخل رقم الجوال أو البريد الإلكتروني.",
      s12: "رمز التحقق غير صحيح",
      s13: "رمز الأمان خاطئ",
      s14: "و",
      s15: "تذكرنى",
    },
    pageTransfer: {
      s1: "أدخل اسم المستخدم.",
      s2: "أدخل المبلغ.",
      s3: "الرمز السري غير صحيح",
      s4: "لايوجد مبلغ كافي",
      s5: "معلومات العملية",
      s6: "تم إتمام العملية بنجاح.",
      s7: "مبلغ التحويل",
    },
    pageWithdraw: {
      s1: "أدخل مبلغ السحب.",
      s2: "ادخل رقم الايبان",
      s3: "ادخل الاسم واللقب",
      s4: "الرمز السري غير صالح",
      s5: "لتتمكن من السحب، يجب عليك تحميل المعلومات والمستندات الخاصة بك.",
      s6: "لايوجد مبلغ كافي",
      s7: "الحد الأدنى للسحب هو الف ريال.",
      s8: "غير ممكن في الوقت الراهن.",
      s9: "تم الدفع بنجاح.",
      s10: "رقم الايبان غير صحيح.",
      s11: "سحب المبلغ",
    },
    pagePinCode: {
      s1: "يجب أن يكون طول الرمز السري 5 أحرف.",
      s2: "تم تسجيل الرمز السري بنجاح",
      s3: "كلمة المرور غير صحيحة",
      s4: "تسجيل الرقم السري",
      s5: "كلمة السر الحالية",
      s6: "الرمز السري المطلوب",
      s7: "تسجيل",
    },
    uploadPage: {
      s1: "رفع الصورة على البطاقة الوطنية",
      s2: "اضغط هنا لتحميل الصورة على البطاقة الوطنية أو اترك صورتك هنا.",
      s3: "الخطأ هو أن حجم الصورة أقل من 300 كيلو بايت.",
      s4: "اضغط هنا إذا كنت مواطنا",
      s5: "رفع الصورة خلف البطاقة الوطنية",
      s6: "اضغط هنا لتحميل الصورة على ظهر البطاقة الوطنية أو اترك صورتك هنا.",
      s7: "تحميل صورة بطاقة الهوية",
      s8: "اضغط هنا لتحميل صورة الصفحة الأولى من شهادة الميلاد أو اترك صورتك هنا.",
      s9: "رفع صورة جواز السفر",
      s10: "انقر هنا لتحميل صورة جواز سفرك أو قم بإسقاط صورتك هنا.",
      s11: "تحميل بطاقة المواطن",
      s12: "اضغط هنا لتحميل صورة بطاقتك الوطنية أو قم بإسقاط صورتك هنا.",
      s13: "اضغط هنا إذا كنت إيرانيا",
      s14: "تحميل بطاقة المواطن",
      s15: "اضغط هنا لتحميل صورة البطاقة الوطنية أو أسقط صورتك هنا.",
      s16: "اضغط هنا إذا كنت إيرانيا",
      s17: "تحميل جوازات سفر المواطنين",
      s18: "اضغط هنا لتحميل صورة البطاقة الوطنية أو ضع صورتك هنا.",
      s19: "قانون التحميل",
      s20: "انقر هنا لتحميل صورة من النظام الأساسي أو أسقط صورتك هنا.",
      s21: "إشعار المؤسسين",
      s22: "انقر هنا لتحميل إعلان المؤسسين أو أسقط صورتك هنا.",
      s23: "رفع أسماء المساهمين",
      s24: "انقر هنا لتحميل صورة لأسماء المساهمين أو قم بإسقاط صورتك هنا.",
      s25: "إشعار الموقعين",
      s26: "انقر هنا لتحميل صورة الجريدة أو قم بإسقاط صورتك هنا.",
      s27: "القانون الوطني",
      s28: "تاريخ الميلاد",
      s29: "الاسم",
      s30: "اللقب",
      s31: "اسم الأب",
      s32: "التأكيد",
      s33: "عودة",
    },
  },
};

export default languages;
