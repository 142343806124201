import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import HomePage from "./pages/homePage/HomePage";
import Transfer from "./pages/transaction/Transfer";
import Login from "./pages/Login";
import NoPage from "./pages/NoPage";
import { useEffect } from "react";

import Withdraw from "./pages/transaction/Withdraw";
import AddDoc from "./pages/doc/AddDoc";
import Contact from "./pages/contacts/Contact";
import BankAccounts from "./pages/bankAccounts/BankAccounts";
import { SlideBottom } from "./components/slideBottom/SlideBottom";
import PinCode from "./pages/pincode/PinCode";
import Charge from "./pages/transaction/Charge";
import { useAddToHomescreenPrompt } from "./components/BamakNotification/useAddToHomescreenPrompt";

export default function App() {
  const [prompt, promptToInstall] = useAddToHomescreenPrompt();

  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<Login />} />
        <Route
          path="/transaction/transfer/:userPhoneOrEmail?"
          element={<Transfer />}
        />
        <Route path="/transaction/charge" element={<Charge />} />
        <Route
          path="/transaction/withdraw/:iban?/:name?"
          element={<Withdraw />}
        />

        <Route path="/pincode/set" element={<PinCode />} />
        <Route path="/doc/add-doc" element={<AddDoc />} />
        <Route path="/contacts" element={<Contact />} />
        <Route path="/bank-accounts" element={<BankAccounts />} />
        <Route
          path="home"
          element={
            <HomePage prompt={prompt} promptToInstall={promptToInstall} />
          }
        />
        <Route path="*" element={<NoPage />} />
      </Routes>
      <SlideBottom />
    </BrowserRouter>
  );
}
