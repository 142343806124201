import { FaAngleDown, FaMoneyBill1 } from "react-icons/fa6";
import LayoutMain from "../../components/LayoutMain";

import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import languages from "../../languages/languages";
import Transaction from "./Transaction";
import { transform } from "../../utils/persianTransform";

export default function HomePage() {
  const [customerList, setCustomerList] = useState([]);
  const language = useSelector((state: any) => state.language);
  const [user, setUser] = useState({
    username: "",
    name: "",
    uniqueName: "",
    profileAvatar: null,
    roles: [],
    creationDate: "",
    mainAccount: {
      accNumber: "",
      lastUpdate: "",
      balance: "0",
      unit: "",
      realTimeBalanceDepositUpdate: true,
    },
    phoneNumber: "",
    pinCodeSet: false,
    redisKey: "",
  });

  const [transactions, setTransactions] = useState([]);

  const fetchData = async () => {
    try {
      let userResP: any = axios.get("/api/current/user");
      let customerP: any = axios.get("/api/alt-account/customer-list");
      let reportTransactionP: any = axios.get(
        "/api/transaction/current-user/report?page=1&size=10&accounts=ALL&transactionType=inout" +
          "&a=" +
          Math.random()
      );

      const [userRes, customer, reportTransaction]: any = await Promise.all([
        userResP,
        customerP,
        reportTransactionP,
      ]);

      setTransactions(reportTransaction.data);
      setUser(userRes.data);
      setCustomerList(customer.data.slice(0, 3));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const renderAccountName = (altAccountMetaData: any, lang: any) => {
    console.log(altAccountMetaData);
    let str = "";
    switch (altAccountMetaData.uniqueId) {
      case "113":
        str += languages[lang].dynamicWords.dollarAccountName;
        break;
      case "114":
        str += languages[lang].dynamicWords.euroAccountName;
        break;
      case "115":
        str += languages[lang].dynamicWords.dirhamAccountName;
        break;
      default:
        str += altAccountMetaData.name;
        break;
    }

    return str;
  };

  const renderAccountUnit = (altAccountMetaData: any, lang: any) => {
    let str = "";
    switch (altAccountMetaData.uniqueId) {
      case "113":
        str += languages[lang].dynamicWords.dollarAccountUnit;
        break;
      case "114":
        str += languages[lang].dynamicWords.euroAccountUnit;
        break;
      case "115":
        str += languages[lang].dynamicWords.dirhamAccountUnit;
        break;
      default:
        str += altAccountMetaData.unit;
        break;
    }

    return str;
  };
  return (
    <LayoutMain>
      <>
        <div className="w-full">
          <div className="mt-4 rounded-lg px-2 py-1 w-11/12 mx-auto shadow shadow-gray-300 hover:shadow-gray-600 relative">
            <h3 className="flex items-center  text-xl border-b border-b-gray-100 p-2">
              <FaMoneyBill1 className="text-2xl" />
              <span className="text-gray-600 p-2">
                {languages[language.language.short].mainAccount}
              </span>
              {language.language.rtl
                ? transform.convertMoney(user.mainAccount.balance, true)
                : transform.convertMoney(user.mainAccount.balance)}{" "}
              {languages[language.language.short].toman}
            </h3>
            <div className="w-full ">
              {customerList.map((item: any) => (
                <h3
                  key={item.altAccountMetaData.uniqueId}
                  className="mt-2 flex items-center  border-b border-b-gray-100 p-2 last:border-b-0"
                >
                  <FaMoneyBill1 className="text-xl" />
                  <span className="text-gray-600 p-2">
                    {renderAccountName(
                      item.altAccountMetaData,
                      language.language.short
                    )}
                  </span>
                  {language.language.rtl
                    ? transform.convertMoney(item.balance, true)
                    : transform.convertMoney(item.balance)}{" "}
                  {`${renderAccountUnit(
                    item.altAccountMetaData,
                    language.language.short
                  )}`}{" "}
                  {}
                </h3>
              ))}
            </div>
            <div
              className={
                customerList.length > 3
                  ? "absolute bottom-0 left-3 p-1 cursor-pointer text-xl text-gray-500"
                  : "hidden"
              }
            >
              <FaAngleDown />
            </div>
          </div>
        </div>
        {transactions.map((tran: any) => (
          <Transaction tran={{ ...tran }} />
        ))}

        <div className="mt-44"></div>
      </>
    </LayoutMain>
  );
}
