import { createSlice } from "@reduxjs/toolkit";

export const languageSlice = createSlice({
  name: "language",
  initialState: {
    language: { short: "fa", rtl: true },
  },
  reducers: {
    changeLanguage: (state, action) => {
      state.language = action.payload;
    },
  },
});

export const { changeLanguage } = languageSlice.actions;
export default languageSlice.reducer;

// export const descremntAsync = (amount) => (dispatch) => {
//   setTimeout(() => {
//     dispatch(decrement(amount));
//   }, 1000);
// };
