import axios from "axios";
import { useEffect, useState } from "react";
import { FaTimes } from "react-icons/fa";
import {
  FaArrowRightArrowLeft,
  FaCheck,
  FaMinus,
  FaPenToSquare,
  FaTrash,
} from "react-icons/fa6";
import { Link } from "react-router-dom";
import languages from "../../languages/languages";
import { useSelector } from "react-redux";

export default function ContactList({ awareChange }: { awareChange: string }) {
  const language = useSelector((state: any) => state.language);

  const [contacts, setContacts] = useState([]);

  const fetchData = async () => {
    try {
      const { data } = await axios.get("/api/contact/report/1/50");
      setContacts(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    (async () => {
      fetchData();
    })();
  }, [awareChange]);

  const [deleteSection, setDeleteSection] = useState("");
  const confirmDelete = (userMailOrPhone: string) => {
    setDeleteSection(userMailOrPhone);
  };

  const actionContact = async (mode: string, userMainOrPhone: string) => {
    if (mode === "delete") {
      await axios.delete(`/api/contact/delete/${userMainOrPhone}`);
    }

    if (mode === "edit") {
      try {
        const { data } = await axios.post("/api/contact/edit", {
          name: editContact.name,
          userMailOrPhone: editContact.userMailOrPhone,
        });
        setAlterSection({ section: "", userMailOrPhone: "" });
        fetchData();
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  };

  const [alterSection, setAlterSection] = useState({
    section: "",
    userMailOrPhone: "",
  });

  const [editContact, setEditContact] = useState({
    userMailOrPhone: "",
    name: "",
  });

  return (
    <div className="w-full mt-8 mb-8">
      <table
        className={
          contacts.length
            ? languages[language.language.short].rtl
              ? "border-collapse table-fixed w-full text-sm text-right"
              : "border-collapse table-fixed w-full text-sm text-left"
            : "hidden"
        }
      >
        <thead>
          <tr>
            <th className="border-b  font-medium p-4 pt-0 pb-3 text-slate-400 ">
              {languages[language.language.short].contact.s3}
            </th>
            <th className="border-b  font-medium p-4 pt-0 pb-3 text-slate-400 ">
              {languages[language.language.short].contact.s2}
            </th>
            <th className="border-b  font-medium p-4 pt-0 pb-3 text-slate-400 ">
              {languages[language.language.short].contact.s14}
            </th>
            <th className="border-b  font-medium p-4 pt-0 pb-3 text-slate-400 ">
              {languages[language.language.short].contact.s15}
            </th>
          </tr>
        </thead>
        <tbody className="bg-white ">
          {contacts.map((contact: any, index: number) => (
            <tr className="hover:bg-slate-100" key={contact.userMailOrPhone}>
              <td className="border-b border-slate-100  p-4  text-slate-500  text-sm max-w-9">
                {index + 1}
              </td>
              <td className="border-b border-slate-100  p-4 text-slate-500 ">
                {alterSection.section === "edit" &&
                alterSection.userMailOrPhone === contact.userMailOrPhone ? (
                  <input
                    type="text"
                    value={editContact.name}
                    onChange={(e) =>
                      setEditContact({
                        userMailOrPhone: contact.userMailOrPhone,
                        name: e.target.value,
                      })
                    }
                    className="p-1 bg-white border border-gray-300 w-full  block text-blue-900 placeholder-gray-500 focus:placeholder-gray-600 text-sm rounded-lg focus:outline-none focus:ring focus:ring-blue-500"
                  />
                ) : (
                  contact.name
                )}
              </td>
              <td className="border-b border-slate-100  p-4  text-slate-500 ">
                {alterSection.section === "delete" &&
                alterSection.userMailOrPhone === contact.userMailOrPhone
                  ? languages[language.language.short].contact.s17
                  : contact.userMailOrPhone}
              </td>

              <td className="border-b border-slate-100  p-4  text-slate-500 flex justify-center gap-4">
                {alterSection.userMailOrPhone !== contact.userMailOrPhone ? (
                  <>
                    <FaPenToSquare
                      className="cursor-pointer"
                      onClick={() => {
                        setAlterSection({
                          section: "edit",
                          userMailOrPhone: contact.userMailOrPhone,
                        });
                        setEditContact({
                          name: contact.name,
                          userMailOrPhone: contact.userMailOrPhone,
                        });
                      }}
                    />
                    <FaTrash
                      className="cursor-pointer"
                      onClick={() =>
                        setAlterSection({
                          section: "delete",
                          userMailOrPhone: contact.userMailOrPhone,
                        })
                      }
                    />
                    <Link
                      to={`/transaction/transfer/${contact.userMailOrPhone}`}
                    >
                      <FaArrowRightArrowLeft />
                    </Link>
                  </>
                ) : (
                  <>
                    <div className="flex justify-center items-center gap-1">
                      <FaCheck
                        className="cursor-pointer"
                        onClick={() =>
                          actionContact(
                            alterSection.section,
                            contact.userMailOrPhone
                          )
                        }
                      />
                    </div>

                    <FaTimes
                      className="cursor-pointer"
                      onClick={() =>
                        setAlterSection({ section: "", userMailOrPhone: "" })
                      }
                    />
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
