import { FaMoneyCheckDollar, FaUser, FaUserLarge } from "react-icons/fa6";
import LayoutMain from "../../components/LayoutMain";
import { useEffect, useState } from "react";
import axios from "axios";
import BankAccountsList from "./BankAccountsList";
import messages from "../../utils/messages";
import { useSelector } from "react-redux";
import languages from "../../languages/languages";

export default function BankAccounts() {
  const language = useSelector((state: any) => state.language);
  const [state, setState] = useState({
    name: "",
    iban: "",
    msg: "",
  });

  const [awareChild, setAwareChild] = useState("");

  const addIBAN = async (e: any) => {
    if (messages.wait === state.msg) return;
    setState({ ...state, msg: messages.wait });
    e.preventDefault();
    if (state.iban.length !== 24) {
      return setState({
        ...state,
        msg: languages[language.language.short].bankAccount.s1,
      });
    }

    if (!state.name.trim()) {
      setState({
        ...state,
        msg: languages[language.language.short].bankAccount.s2,
      });
    }

    try {
      const { data } = await axios.put("/api/userBankAccount/add", {
        accountId: state.iban,
        name: state.name,
        type: "",
        bank: "",
        bankLogo: "",
      });

      setState({
        ...state,
        name: "",
        iban: "",
        msg: languages[language.language.short].bankAccount.s3,
      });
      setAwareChild("" + Date.now());
    } catch (error: any) {
      if (error?.response?.data) {
        if (error.response.data.persisted) {
          return setState({
            ...state,
            msg: languages[language.language.short].bankAccount.s4,
          });
        }

        setState({
          ...state,
          msg: languages[language.language.short].bankAccount.s5,
        });
        // console.log(error?.response?.data);
      }
    }
  };

  useEffect(() => {}, []);

  return (
    <LayoutMain>
      <div className="w-full">
        <h3 className="text-gray-800  text-center bg-red my-8 flex w-full justify-center items-center gap-1 ">
          <FaUserLarge className="text-xl" />
          <span>{languages[language.language.short].bankAccount.s9}</span>
        </h3>
        <div className="w-11/12 mx-auto max-w-96 cursor-pointer p-4 mb-4 bg-slate-200 rounded-lg text-gray-700">
          <form onSubmit={addIBAN}>
            <p className="text-gray-600 text-base p-1">
              {languages[language.language.short].bankAccount.s6}
            </p>
            <div className="bg-white border border-gray-300 w-11/12 flex items-center ltr-direction text-blue-900 placeholder-gray-500 focus:placeholder-gray-600 text-sm rounded-lg focus:outline-none focus:ring focus:ring-blue-500 max-w-md mx-auto radious">
              <div className="p-2">
                <FaUser className="text-2xl" />
              </div>
              <input
                type="text"
                placeholder={languages[language.language.short].bankAccount.s7}
                onChange={(e) => setState({ ...state, name: e.target.value })}
                className="bg-white border-0 border-gray-300 w-full p-1 block outline-none rounded text-center text-gray-700"
              />
            </div>
            <div className="bg-white border mt-8 border-gray-300 w-11/12 flex items-center ltr-direction text-blue-900 placeholder-gray-500 focus:placeholder-gray-600 text-sm rounded-lg focus:outline-none focus:ring focus:ring-blue-500 max-w-md mx-auto radious">
              <div className="p-2 text-blue-900 ">
                <span className="text-xl">IR</span>
              </div>
              <input
                type="text"
                maxLength={24}
                minLength={24}
                placeholder={languages[language.language.short].bankAccount.s8}
                onChange={(e) => setState({ ...state, iban: e.target.value })}
                className="bg-white border-0 border-gray-300 w-full p-1 block outline-none rounded text-center text-gray-500"
              />
            </div>
            <p className="my-2">{state.msg}</p>
            <button
              type="submit"
              className="border mt-6 w-11/12 flex justify-center items-center gap-2 hover:bg-teal-600 hover:text-white font-medium rounded-lg text-base px-5 py-2.5 mb-2 bg-teal-500 text-white mx-auto"
            >
              <FaMoneyCheckDollar />
              {languages[language.language.short].bankAccount.s10}
            </button>
          </form>
        </div>
        <BankAccountsList awareChange={awareChild} />
      </div>
    </LayoutMain>
  );
}
