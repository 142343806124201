import { useEffect, useState } from "react";

import { FaTimes } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { setShowPWAInstalledButton } from "../../redux/pwaSlice";

function BamakNotification({ prompt, promptToInstall }: any) {
  const { showPWAInstallButton } = useSelector((state: any) => state.pwa);
  const result = ((window as any).navigator as any).getInstalledRelatedApps();
  console.log(result.then(console.log));

  const dispatch = useDispatch();

  let showInstallPrompt = async () => {
    try {
      await promptToInstall();
      if (prompt)
        prompt.userChoice.then((choiceResult: any) => {
          if (choiceResult.outcome === "accepted") {
            // console.log("User accepted the A2HS prompt");
            // create localStorage

            dispatch(setShowPWAInstalledButton(false));

            localStorage.setItem("isPWAInstalled", "true");
          } else {
            // console.log("User dismissed the A2HS prompt");
          }
          // deferredPrompt = null;
        });
    } catch (e) {
      console.log(e);
      // console.log(e);
    }
  };

  useEffect(() => {
    let isPWAInstalled = sessionStorage?.getItem("isPWAInstalled");

    if (isPWAInstalled === "true") {
      return;
    }

    try {
      let isPWAInstalled = localStorage?.getItem("isPWAInstalled");
      if (isPWAInstalled === "true") {
        dispatch(setShowPWAInstalledButton(false));
      } else {
        dispatch(setShowPWAInstalledButton(true));
      }
    } catch (error) {}
  }, []);

  return (
    <div
      className={
        showPWAInstallButton
          ? " btext-center p-1 bg-white flex justify-between items-center border-b border-b-gray-100"
          : "hidden"
      }
    >
      <div className="flex items-center my-2">
        <span
          onClick={() => {
            dispatch(setShowPWAInstalledButton(false));
            sessionStorage.setItem("isPWAInstalled", "true");
          }}
        >
          <FaTimes className="text-xl cursor-pointer text-gray-400" />
        </span>
        <div className="mr-4">
          <img src="/pwa/moneay.png" className="w-9" alt="مانیزی" />
        </div>
        <span className="whitespace-nowrap flex justify-center items-center mx-1 ">
          مانیزی به صفحه اصلی اضافه شود؟
        </span>
      </div>
      <button
        className="border-0 bg-teal-500 py-2 px-5 mx-2 flex justify-center items-center rounded text-base text-white "
        type="button"
        onClick={showInstallPrompt}
      >
        بله
      </button>
    </div>
  );
}

export default BamakNotification;
