import {
  FaArrowsToDot,
  FaCheck,
  FaCircleInfo,
  FaClipboardUser,
  FaMoneyBill1,
  FaPencil,
  FaUser,
} from "react-icons/fa6";
import LayoutMain from "../../components/LayoutMain";

import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import languages from "../../languages/languages";
import messages from "../../utils/messages";
import { useParams } from "react-router-dom";
import {
  changeLightBoxState,
  setLevel,
  setPinDet,
} from "../../redux/lightboxSlice";
import { transform } from "../../utils/persianTransform";

export default function Withdraw() {
  const language = useSelector((state: any) => state.language);

  const dispatch = useDispatch();

  const { pinDet } = useSelector((state: any) => state.lightbox);

  const [state, setState] = useState({
    fullname: "",
    accountId: "",
    amount: "",
    destinationPaymentId: "",
    description: "",
    tariff: 0,
    page: "deposit",
  });

  const [msg, setMSG] = useState("");

  const fetchData = async () => {
    try {
    } catch (error) {
      console.log(error);
    }
  };

  const depositConfirm = async () => {
    let amount = state.amount
      ? transform.persianNumberToEng(state.amount)
      : "0";
    amount = +amount.replace(/[^0-9]/g, "");

    console.log(amount);
    if (!+amount) {
      return setMSG(languages[language.language.short].pageWithdraw.s1);
    }

    if (!state.accountId) {
      return setMSG(languages[language.language.short].pageWithdraw.s2);
    }

    if (!state.fullname) {
      return setMSG(languages[language.language.short].pageWithdraw.s3);
    }

    try {
      const res = await axios.post(`/api/transaction/withdraw/tarrif`, {
        bankAccount: state.accountId,
        amount: +amount,
      });
      setMSG("");
      setState({ ...state, tariff: res.data, page: "deposit-confirm" });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const { iban, name } = useParams();

  useEffect(() => {
    setState({
      ...state,
      fullname: name ? name.replace(/-/g, " ") : "",
      accountId: iban ? iban : "",
    });
  }, []);

  const withdrawRequest = async () => {
    let amount = transform.persianNumberToEng(state.amount);
    amount = +amount.replace(/[^0-9]/g, "");

    console.log(amount);
    try {
      if (msg === messages.wait) return;

      setMSG(messages.wait);

      const res = await axios.post(
        "/api/transaction/withdraw",
        {
          amount: +amount,
          bankAccount: {
            accountId: state.accountId,
            bank: "",
            bankLogo: "",
            name: state.fullname,
            type: "",
          },
          description: state.description,
          destinationPaymentId: state.destinationPaymentId,
        },
        {
          headers: {
            Pincode: pinDet.pin,
          },
        }
      );

      setMSG("");
      dispatch(setPinDet({ pin: "", msg: "" }));
      dispatch(changeLightBoxState(false));
      setState({ ...state, page: "deposit-success" });
    } catch (error: any) {
      switch (error?.response?.data) {
        case "Pin Code Not Match":
          dispatch(setLevel("pinCode"));
          pinDet.pin &&
            dispatch(
              setPinDet({
                msg: languages[language.language.short].pageWithdraw.s4,
              })
            );
          setMSG("");
          dispatch(changeLightBoxState(true));
          break;
        case "Pin Code in Header is Empty":
          dispatch(setLevel("pinCode"));
          setMSG("");
          dispatch(changeLightBoxState(true));
          break;
        case "The accountId is not valid":
          setMSG(languages[language.language.short].pageWithdraw.s10);
          break;

        case "برای برخورداری از امکان برداشت لازم است اطلاعات و مدارک خود را بارگذاری نمایید":
          setMSG(languages[language.language.short].pageWithdraw.s5);
          break;

        case "not enough balance":
          setMSG(languages[language.language.short].pageWithdraw.s6);
          break;

        case error?.response?.data?.includes(
          "Amount is less than minimum valid"
        )
          ? error?.response?.data
          : "lksdfjlksfj":
          setMSG(languages[language.language.short].pageWithdraw.s7);
          break;

        default:
          console.log(
            error?.response?.data?.includes("Amount is less than minimum valid")
          );
          setMSG(languages[language.language.short].pageWithdraw.s8);
      }
    }
  };

  const [transferType, setTransferType] = useState("0");
  let transferUnitPersian =
    languages[language.language.short].dynamicWords.tomanAccountUnit;
  switch (transferType) {
    case "0":
      transferUnitPersian =
        languages[language.language.short].dynamicWords.tomanAccountUnit;
      break;
    case "113":
      transferUnitPersian =
        languages[language.language.short].dynamicWords.dollarAccountUnit;
      break;
    case "114":
      transferUnitPersian =
        languages[language.language.short].dynamicWords.euroAccountUnit;
      break;
    case "115":
      transferUnitPersian =
        languages[language.language.short].dynamicWords.dirhamAccountUnit;
  }

  const [unitsList, setUnitsList] = useState<any>([]);
  useEffect(() => {
    (async () => {
      try {
        const { data }: any = await axios.get("/api/alt-account/customer-list");
        console.log([...unitsList, ...data].slice(0, 3));
        setUnitsList([...unitsList, ...data].slice(0, 3));
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const renderAccountUnit = (unit: string) => {
    let transferUnitPersian =
      languages[language.language.short].dynamicWords.tomanAccountUnit;
    switch (unit) {
      case "تومان":
        transferUnitPersian =
          languages[language.language.short].dynamicWords.tomanAccountUnit;
        break;
      case "دلار":
        transferUnitPersian =
          languages[language.language.short].dynamicWords.dollarAccountUnit;
        break;
      case "درهم":
        transferUnitPersian =
          languages[language.language.short].dynamicWords.dirhamAccountUnit;
        break;
      case "یورو":
        transferUnitPersian =
          languages[language.language.short].dynamicWords.euroAccountUnit;
    }
    return transferUnitPersian;
  };

  useEffect(() => {
    setMSG("");
    pinDet.aware && withdrawRequest();
  }, [pinDet.aware]);

  const [ibans, setIBANS] = useState([]);

  const giveIBANSuggestion = async (e: any) => {
    try {
      const { data }: any = await axios.get(
        "/api/userBankAccount/search/" + e.target.value + "/sheba"
      );

      typeof data !== "string" ? setIBANS(data) : setIBANS([]);
    } catch (error) {
      setIBANS([]);
    }
  };

  const [ibans2, setIBANS2] = useState([]);

  const giveIBANSuggestion2 = async (e: any) => {
    try {
      const { data }: any = await axios.get(
        "/api/userBankAccount/search/" + e.target.value + "/sheba"
      );

      typeof data !== "string" ? setIBANS2(data) : setIBANS2([]);
    } catch (error) {
      setIBANS2([]);
    }
  };

  const amountFunc = (e: any) => {
    let amount;
    try {
      amount = transform.persianNumberToEng(e.target.value);

      amount = amount.replace(/[^0-9]/g, "");
    } catch (e: any) {
      amount = "";
    }

    if (languages[language.language.short].rtl) {
    }

    setState({
      ...state,
      amount: amount
        ? languages[language.language.short].rtl
          ? transform.engNumberToPersian(transform.convertMoney(amount))
          : transform.convertMoney(amount)
        : "",
    });
  };

  return (
    <LayoutMain>
      <div className="w-full">
        <div className={state.page === "deposit-success" ? "w-full" : "hidden"}>
          <h3 className="text-green-600 text-center mt-8">
            {languages[language.language.short].pageWithdraw.s9}
          </h3>
        </div>
        <div className={state.page === "deposit" ? "w-full" : "hidden"}>
          <h3 className="text-center bg-red my-8">
            {languages[language.language.short].withdraw}
          </h3>

          <div
            className="w-full text-sm"
            dangerouslySetInnerHTML={{
              __html: languages[language.language.short].withdrawLaws,
            }}
          />

          <select
            onChange={(e: any) => {
              setTransferType(e.target.value);
              setMSG("");
            }}
            value={transferType}
            className="max-w-md block focus:outline-none mx-auto w-11/12 bg-gray-400 text-white rounded p-1 my-4"
          >
            <option value="0">
              {languages[language.language.short].mainAccount}
            </option>
            {unitsList.map((item: any, index: any) => (
              <option key={index} value={item.altAccountMetaData.uniqueId}>
                {languages[language.language.short].account}{" "}
                {renderAccountUnit(item.altAccountMetaData.unit)}
              </option>
            ))}
          </select>

          {/* <div className="bg-white border border-gray-300 w-11/12 flex items-center ltr-direction text-blue-900 placeholder-gray-500 focus:placeholder-gray-600 text-sm rounded-lg focus:outline-none focus:ring focus:ring-blue-500 max-w-md mx-auto radious">
            <div className="p-2">
              <FaUser className="text-2xl" />
            </div>
            <input
              type="text"
              name="email"
              id="email"
              placeholder={languages[language.language.short].fullname}
              className="bg-white border-0 border-gray-300 w-full p-1 block outline-none rounded text-center text-gray-700"
              onChange={(e) => {
                setState({ ...state, fullname: e.target.value });
                giveIBANSuggestion(e);
              }}
              value={state.fullname}
            />
          </div> */}

          <div className="relative w-11/12 max-w-md mx-auto mt-8">
            <div className="bg-white border border-gray-300 w-full flex items-center ltr-direction text-blue-900 placeholder-gray-500 focus:placeholder-gray-600 text-sm rounded-lg focus:outline-none focus:ring focus:ring-blue-500 max-w-md mx-auto radious">
              <div className="p-2">
                <FaUser className="text-2xl" />
              </div>
              <input
                type="text"
                placeholder={languages[language.language.short].fullname}
                className="bg-white border-0 border-gray-300 w-full p-1 block outline-none rounded text-center text-gray-500 "
                onChange={(e) => {
                  setState({ ...state, fullname: e.target.value });
                  giveIBANSuggestion2(e);
                }}
                value={state.fullname}
              />
            </div>
            <div className="absolute w-full bg-slate-100 z-30">
              {ibans2.length ? (
                <div className="w-full block  bg-gray-50 outline-none p-1  border border-gray-300 text-gray-600 rounded">
                  {ibans2.map((c: any) => (
                    <div
                      className="flex justify-around items-center border-b border-b-gray-200 p-1 child  last:border-0 cursor-pointer hover:bg-gray-100 py-2"
                      onClick={(e: any) => {
                        setState({
                          ...state,
                          accountId: c.accountId,
                          fullname: c.name,
                        });

                        setIBANS2([]);
                      }}
                      key={c.userMailOrPhone}
                    >
                      <span className="flex justify-center gap-1 items-center">
                        {c.name} <FaClipboardUser />
                        IR{c.accountId}
                      </span>
                      <div>
                        <img
                          className="rounded-full w-12 h-12"
                          src={c.bankLogo}
                          alt="bank"
                        />
                      </div>
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
          </div>

          <div className="relative w-11/12 max-w-md mx-auto mt-8">
            <div className="bg-white border border-gray-300 w-full flex items-center ltr-direction text-blue-900 placeholder-gray-500 focus:placeholder-gray-600 text-sm rounded-lg focus:outline-none focus:ring focus:ring-blue-500 max-w-md mx-auto radious">
              <div className="p-2 text-blue-900 ">
                <span className="text-xl">IR</span>
              </div>
              <input
                type="text"
                autoComplete="off"
                onChange={(e) => {
                  setState({ ...state, accountId: e.target.value });
                  giveIBANSuggestion(e);
                }}
                placeholder={languages[language.language.short].iban}
                value={state.accountId}
                className="bg-white border-0 border-gray-300 w-full p-1 block outline-none rounded text-center text-gray-500 "
              />
            </div>
            <div className="absolute w-full bg-slate-100">
              {ibans.length ? (
                <div className="w-full block  bg-gray-50 outline-none p-1  border border-gray-300 text-gray-600 rounded">
                  {ibans.map((c: any) => (
                    <div
                      className="flex justify-around items-center border-b border-b-gray-200 p-1 child  last:border-0 cursor-pointer hover:bg-gray-100 py-2"
                      onClick={(e: any) => {
                        setState({
                          ...state,
                          accountId: c.accountId,

                          fullname: c.name,
                        });

                        setIBANS([]);
                      }}
                      key={c.userMailOrPhone}
                    >
                      <span className="flex justify-center gap-1 items-center">
                        {c.name} <FaClipboardUser />
                        IR{c.accountId}
                      </span>
                      <div>
                        <img
                          className="rounded-full w-12 h-12"
                          src={c.bankLogo}
                          alt="bank"
                        />
                      </div>
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
          </div>
          <div className="bg-white border mt-8 border-gray-300 w-11/12 flex items-center ltr-direction text-blue-900 placeholder-gray-500 focus:placeholder-gray-600 text-sm rounded-lg focus:outline-none focus:ring focus:ring-blue-500 max-w-md mx-auto radious">
            <div className="p-2">
              <FaMoneyBill1 className="text-2xl" />
            </div>

            <input
              type="text"
              autoComplete="off"
              onChange={amountFunc}
              placeholder={`${
                languages[language.language.short].amount
              } ${transferUnitPersian}`}
              value={state.amount}
              className="bg-white border-0 border-gray-300 w-full p-1 block outline-none rounded text-center text-gray-500"
            />
          </div>
          <p
            className={
              state.amount
                ? "text-center w-full mt-3 text-gray-600 text-sm"
                : "hidden"
            }
          >
            {languages[language.language.short].pageWithdraw.s11}:{" "}
            {state.amount} {languages[language.language.short].toman}
          </p>
          <div className="bg-white border mt-8 border-gray-300 w-11/12 flex items-center ltr-direction text-blue-900 placeholder-gray-500 focus:placeholder-gray-600 text-sm rounded-lg focus:outline-none focus:ring focus:ring-blue-500 max-w-md mx-auto radious">
            <div className="p-2">
              <FaArrowsToDot className="text-2xl" />
            </div>
            <input
              type="text"
              onChange={(e) =>
                setState({ ...state, destinationPaymentId: e.target.value })
              }
              placeholder={languages[language.language.short].paymentId}
              value={state.destinationPaymentId}
              className="bg-white border-0 border-gray-300 w-full p-1 block outline-none rounded text-center text-gray-500"
            />
          </div>
          <textarea
            className="bg-white border border-gray-300 w-11/12 outline-none max-w-md mx-auto rounded h-20 block mt-8 resize-none p-2 text-sm"
            placeholder={languages[language.language.short].description}
            onChange={(e) =>
              setState({ ...state, description: e.target.value })
            }
            value={state.description}
          ></textarea>
          <div className="clear-both"></div>
          <p className="text-center mt-8">{msg}</p>
          <button
            type="button"
            onClick={depositConfirm}
            className="border border-teal-500  bg-teal-400 hover:bg-teal-500 text-white  rounded-lg  px-5 py-2.5  w-11/12 text-base max-w-44 block mx-auto"
          >
            {languages[language.language.short].confirmation}
          </button>
        </div>
        <div className={state.page === "deposit-confirm" ? "w-full" : "hidden"}>
          <div className="w-full text-sm mt-4 mx-4">
            <h3 className="flex items-center gap-2 text-base">
              <FaCircleInfo className="text-xl" />{" "}
              {languages[language.language.short].WithdrawLaws1}
            </h3>
            <ul className="list-disc p-1 mr-4 mt-1 mb-3">
              <li className="mt-4">
                {languages[language.language.short].WithdrawLaws2}
              </li>
              <li className="mt-4">
                <span className="bg-red-400 py-1 px-2 rounded text-white">
                  {languages[language.language.short].withdrawLaws3Head}
                  {state.tariff} {languages[language.language.short].toman}
                  {languages[language.language.short].tomanAccountUnit}
                </span>
              </li>
              <li className="mt-4">
                {languages[language.language.short].withdrawLaws4Head}
                <span className="bg-red-400 py-1 px-2 rounded text-white">
                  {+state.amount + +state.tariff}
                </span>
                {languages[language.language.short].withdrawLaws4Tail}
              </li>
            </ul>
          </div>
          <div className={msg ? "w-full" : "hidden"}>
            {
              <div
                className={
                  messages.wait === msg
                    ? "mx-4  w-full text-green-600 text-sm flex items-center gap-2 my-4"
                    : "mx-4 w-full text-red-500 text-sm flex items-center gap-2 my-4"
                }
              >
                <FaCircleInfo /> {msg}
              </div>
            }
          </div>
          <div className="flex mt-1 md:flex-row flex-col mx-2">
            <button
              type="button"
              className="text-teal-500 bg-white border border-teal-500  hover:bg-teal-500 hover:text-white  rounded-lg text-sm px-5 py-2.5 me-2 mb-2  w-full"
              onClick={withdrawRequest}
            >
              {languages[language.language.short].confirmation}
            </button>
            <button
              onClick={(e) => setState({ ...state, page: "deposit" })}
              type="button"
              className="text-teal-500 bg-white border border-teal-500  hover:bg-teal-500 hover:text-white  rounded-lg text-sm px-5 py-2.5 me-2 mb-2  w-full"
            >
              {languages[language.language.short].disapproval}
            </button>
          </div>
        </div>
        <div className="h-60 w-full"> </div>
      </div>
    </LayoutMain>
  );
}
