import React from "react";
import ReactDOM from "react-dom/client";
import "./themes/blue/styles/main.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store from "./redux/store";
import { Provider } from "react-redux";
import config from "./configs/config";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Provider store={store}>
    <div className="w-full max-w-2xl mx-auto relative">
      {config.oldUrl ? (
        <a
          href={config.oldUrl}
          className="w-full text-center py-1 bg-gray-200 block text-gray-600"
        >
          ورود به نسخه قدیم مانیزی
        </a>
      ) : null}
      <App />
    </div>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
