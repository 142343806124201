import {
  FaCheck,
  FaCircleInfo,
  FaMoneyBill1,
  FaPencil,
  FaUser,
} from "react-icons/fa6";
import LayoutMain from "../../components/LayoutMain";
import { FaGasPump } from "react-icons/fa6";

import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import languages from "../../languages/languages";
import { Link, useParams } from "react-router-dom";
import profile from "../../themes/blue/images/profile.jpg";
import {
  changeLightBoxState,
  setLevel,
  setPinDet,
} from "../../redux/lightboxSlice";

export default function Transfer() {
  const dispatch = useDispatch();

  const [msg, setMSG] = useState("");
  const [state, setState] = useState({
    filledUsername: "",
    filledAmount: "",
    name: "",
    description: "",
    username: "",
    uniqueName: "",
    profileAvatar: "",
    page: "payment-fill",

    when: "",
    amount: "",
    trxCode: "",
    balance: "",
    sourceUsername: "",
    destinationUsername: "",
    accountUniqId: "",
    persianType: "",
    profileAvatarUrl: "",
    sign: "",
  });

  const language = useSelector((state: any) => state.language);
  const { pinDet } = useSelector((state: any) => state.lightbox);

  const fetchData = async () => {
    try {
    } catch (error) {
      console.log(error);
    }
  };

  const { userPhoneOrEmail } = useParams();

  const paymentConfirm = async () => {
    try {
      if (!state.filledUsername) {
        return setMSG(languages[language.language.short].pageTransfer.s1);
      }

      if (!state.filledAmount) {
        return setMSG(languages[language.language.short].pageTransfer.s2);
      }
      const res = await axios.get(`/api/find/username/${state.filledUsername}`);
      console.log(res.data);
      setState({ ...state, ...res.data, page: "payment-confirm" });
    } catch (error) {
      console.log(error);
    }
  };

  const [pin, setPin] = useState("");

  const pay = async () => {
    try {
      //check is user set pincode

      if (transferType === "0") {
        const res = await axios.post(
          "/api/transaction/user2user",
          {
            username: state.filledUsername,
            amount: +state.filledAmount,
            description: state.description,
          },
          {
            headers: {
              Pincode: pinDet.pin,
            },
          }
        );
        dispatch(setLevel(""));
        dispatch(changeLightBoxState(false));
        dispatch(setPinDet({ msg: "", pin: "" }));

        setState({ ...state, ...res.data, page: "pay" });
        setState({ ...state, ...res.data, page: "pay" });
      } else {
        const res = await axios.post(
          "/api/transaction/user2user/alt",
          {
            username: state.filledUsername,
            amount: +state.filledAmount,
            description: state.description,
            altAccountUniqueId: transferType,
          },
          {
            headers: {
              Pincode: pinDet.pin,
            },
          }
        );

        dispatch(setLevel(""));
        dispatch(changeLightBoxState(false));
        dispatch(setPinDet({ msg: "", pin: "" }));

        setState({ ...state, ...res.data, page: "pay" });
      }
    } catch (error: any) {
      const { data } = error.response;
      if (data === "Pin Code in Header is Empty") {
        dispatch(setLevel("pinCode"));
        dispatch(changeLightBoxState(true));
      }

      if (data === "Pin Code Not Match") {
        dispatch(setLevel("pinCode"));
        dispatch(changeLightBoxState(true));
        pinDet.aware &&
          dispatch(
            setPinDet({
              msg: languages[language.language.short].pageTransfer.s3,
            })
          );
      }
      if (data === "not enough balance") {
        dispatch(setLevel("pinCode"));
        dispatch(changeLightBoxState(true));
        pinDet.aware &&
          dispatch(
            setPinDet({
              msg: languages[language.language.short].pageTransfer.s4,
            })
          );
        setMSG("موجودی کافی نیست");
      }
    }
  };

  useEffect(() => {
    setState({
      ...state,
      filledUsername: userPhoneOrEmail ? userPhoneOrEmail : "",
    });
  }, []);

  const [transferType, setTransferType] = useState("0");
  let transferUnitPersian =
    languages[language.language.short].dynamicWords.tomanAccountUnit;
  switch (transferType) {
    case "0":
      transferUnitPersian =
        languages[language.language.short].dynamicWords.tomanAccountUnit;
      break;
    case "113":
      transferUnitPersian =
        languages[language.language.short].dynamicWords.dollarAccountUnit;
      break;
    case "114":
      transferUnitPersian =
        languages[language.language.short].dynamicWords.euroAccountUnit;
      break;
    case "115":
      transferUnitPersian =
        languages[language.language.short].dynamicWords.dirhamAccountUnit;
  }

  const [unitsList, setUnitsList] = useState<any>([]);
  useEffect(() => {
    (async () => {
      try {
        const { data }: any = await axios.get("/api/alt-account/customer-list");
        console.log([...unitsList, ...data].slice(0, 3));
        setUnitsList([...unitsList, ...data].slice(0, 3));
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const renderAccountUnit = (unit: string) => {
    let transferUnitPersian =
      languages[language.language.short].dynamicWords.tomanAccountUnit;
    switch (unit) {
      case "تومان":
        transferUnitPersian =
          languages[language.language.short].dynamicWords.tomanAccountUnit;
        break;
      case "دلار":
        transferUnitPersian =
          languages[language.language.short].dynamicWords.dollarAccountUnit;
        break;
      case "درهم":
        transferUnitPersian =
          languages[language.language.short].dynamicWords.dirhamAccountUnit;
        break;
      case "یورو":
        transferUnitPersian =
          languages[language.language.short].dynamicWords.euroAccountUnit;
    }
    return transferUnitPersian;
  };

  useEffect(() => {
    pinDet.aware && pay();
  }, [pinDet.aware]);

  const [contacts, setContacts] = useState([]);

  const giveContactsSuggestion = async (e: any) => {
    try {
      const { data }: any = await axios.get(
        "/api/contact/search/" + e.target.value
      );

      console.log(data);

      typeof data !== "string" ? setContacts(data) : setContacts([]);
    } catch (error) {
      setContacts([]);
    }
  };

  return (
    <LayoutMain>
      <div className="w-full">
        <div
          className={state.page === "payment-fill" ? "payment-fill" : "hidden"}
        >
          <h3 className="text-center bg-red my-8">
            {languages[language.language.short].moneyTransfer}
          </h3>

          <select
            onChange={(e: any) => {
              setTransferType(e.target.value);
              setMSG("");
            }}
            value={transferType}
            className="max-w-md block focus:outline-none mx-auto w-11/12 bg-gray-400 text-white rounded p-1 my-4"
          >
            <option value="0">
              {languages[language.language.short].mainAccount}
            </option>
            {unitsList.map((item: any, index: any) => (
              <option key={index} value={item.altAccountMetaData.uniqueId}>
                {languages[language.language.short].account}{" "}
                {renderAccountUnit(item.altAccountMetaData.unit)}
              </option>
            ))}
          </select>
          <div className="relative w-11/12 max-w-md mx-auto ">
            <div className="bg-white border border-gray-300 w-full flex items-center ltr-direction text-blue-900 placeholder-gray-500 focus:placeholder-gray-600 text-sm rounded-lg focus:outline-none focus:ring focus:ring-blue-500 max-w-md mx-auto radious">
              <div className="p-2">
                <FaUser className="text-2xl" />
              </div>
              <input
                type="text"
                placeholder={
                  languages[language.language.short].destinationUsername
                }
                className="bg-white border-0 border-gray-300 w-full p-1 block outline-none rounded text-center text-gray-700"
                onChange={(e) => {
                  setState({ ...state, filledUsername: e.target.value });
                  giveContactsSuggestion(e);
                }}
                value={state.filledUsername}
              />
            </div>
            <div className="absolute w-full bg-slate-100">
              {contacts.length ? (
                <div className="w-full block  bg-gray-50 outline-none p-1  border border-gray-300 text-gray-600 rounded">
                  {contacts.map((c: any) => (
                    <div
                      className="border-b border-b-gray-200 p-1 child  last:border-0 cursor-pointer hover:bg-gray-100 py-2"
                      onClick={(e: any) => {
                        console.log("hello");
                        setState({
                          ...state,
                          filledUsername: c.userMailOrPhone,
                        });

                        setContacts([]);
                      }}
                      key={c.userMailOrPhone}
                    >
                      {`${c.name} | ${c.userMailOrPhone}`}
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
          </div>
          <div className="bg-white border mt-8 border-gray-300 w-11/12 flex items-center ltr-direction text-blue-900 placeholder-gray-500 focus:placeholder-gray-600 text-sm rounded-lg focus:outline-none focus:ring focus:ring-blue-500 max-w-md mx-auto radious">
            <div className="p-2">
              <FaMoneyBill1 className="text-2xl" />
            </div>
            <input
              type="text"
              className="bg-white border-0 border-gray-300 w-full p-1 block outline-none rounded text-center text-gray-500"
              onChange={(e) =>
                setState({ ...state, filledAmount: e.target.value })
              }
              placeholder={`${
                languages[language.language.short].amount
              } ${transferUnitPersian}`}
              value={state.filledAmount}
            />

            {/* مستندات api انتقال وجه از حساب مجازی

آدرس: /api/transaction/user2user/alt

ورودی ها:
همان ورودی های سرویس انتقال وجه معمولی 
+ altAccountUniqueId به معنای کد یکتای حساب مجازی  */}
          </div>
          <textarea
            className="bg-white border border-gray-300 w-11/12 outline-none max-w-md mx-auto rounded h-20 block mt-8 resize-none p-2 text-sm"
            placeholder={
              languages[language.language.short]
                .insertTransactionDescriptionHere
            }
            onChange={(e) =>
              setState({ ...state, description: e.target.value })
            }
            value={state.description}
          ></textarea>
          <div className="clear-both"></div>
          <p className="text-center mt-8 ">{msg}</p>
          <button
            type="button"
            onClick={paymentConfirm}
            className="border border-teal-500  bg-teal-400 hover:bg-teal-500 text-white  rounded-lg  px-5 py-2.5 w-11/12 text-base max-w-44 block mx-auto"
          >
            {languages[language.language.short].continue}
          </button>
        </div>

        <div
          className={
            state.page === "payment-confirm" ? "payment-fill" : "hidden"
          }
        >
          <h3 className="text-center bg-red my-8">
            {languages[language.language.short].confirmationOfMoneyTransfer}
          </h3>

          <div className="bg-white pb-6 w-11/12  items-center ltr-direction text-blue-900 placeholder-gray-500 focus:placeholder-gray-600 text-sm rounded-lg focus:outline-none focus:ring focus:ring-blue-500 max-w-md mx-auto radious flex flex-col">
            <div className="flex justify-start items-center w-full flex-col p-2">
              <img
                className="w-20 h-20 rounded-full"
                src={
                  state.profileAvatar
                    ? `https://moneasy.ir/portal/external/Avatar/${state.profileAvatar}`
                    : profile
                }
                alt="img"
              />

              <h3 className="text-gray-700 text-lg">{state.name}</h3>
            </div>
            <h3 className="text-base">
              {languages[language.language.short].amount}: {state.filledAmount}{" "}
              {transferUnitPersian}
            </h3>
            <div className="mt-8">
              <span>{languages[language.language.short].description}: </span>
              {state.description ? state.description : "-"}
            </div>
            <div className="w-full flex justify-center items-center gap-7 mt-8">
              <button
                type="button"
                onClick={() => {
                  setState({ ...state, page: "payment-fill" });
                  setMSG("");
                }}
                className="border orange-teal-500  bg-orange-400 hover:bg-orange-500 text-white  rounded-lg py-1  px-14  text-base max-w-44 flex justify-center items-center gap-1"
              >
                <FaPencil className="text-xl" />
                {languages[language.language.short].edit}
              </button>
              <button
                onClick={pay}
                type="button"
                className="border border-teal-500  bg-teal-400 hover:bg-teal-500 text-white  rounded-lg py-1  px-14 text-base max-w-44 flex justify-center items-center gap-1"
              >
                <span>
                  <FaCheck className="text-xl" />
                </span>
                {languages[language.language.short].confirmation}
              </button>
            </div>
          </div>
        </div>
        <div className={state.page === "pay" ? "payment-fill" : "hidden"}>
          <h3 className="text-center bg-red my-8 flex justify-center items-center gap-2">
            <FaCircleInfo className="text-2xl" />
            {languages[language.language.short].pageTransfer.s5}
          </h3>
          <h3 className="text-green-500 text-xl text-center">
            {languages[language.language.short].pageTransfer.s6}
          </h3>
        </div>
      </div>
    </LayoutMain>
  );
}
