import { useEffect, useRef, useState } from "react";
import moneasyConfig from "../configs/config";
import logo from "../themes/blue/images/logo-text.png";
import {
  FaArrowLeft,
  FaArrowRotateRight,
  FaLanguage,
  FaRegSquare,
  FaRegSquareCheck,
} from "react-icons/fa6";
import axios from "axios";
import messages from "../utils/messages";
import { useNavigate } from "react-router-dom";
import languages from "./../languages/languages";
import { useDispatch, useSelector } from "react-redux";
import { transform } from "../../src/utils/persianTransform";
import { FaArrowRight } from "react-icons/fa6";
import randomstring from "randomstring";
import "react-phone-number-input/style.css";
import PhoneInput, {
  getCountryCallingCode,
  parsePhoneNumber,
} from "react-phone-number-input";
import flags from "react-phone-number-input/flags";
import { changeLanguage } from "../redux/languageSlice";
import {
  defaultCountries,
  parseCountry,
  PhoneInput as PhoneInput2,
} from "react-international-phone";
import "react-international-phone/style.css";
let interval: any;
export default function Login() {
  const language = useSelector((state: any) => state.language);
  const inputRef = useRef<HTMLInputElement>(null);

  const [msg, setMSG] = useState<string>("");

  const [state, setState] = useState<any>({
    username: "",
    otpPassword: "",
    password: "",
    page: "isRegistered",
    rememberMe: null,
  });

  const navigate = useNavigate();

  const checkIsUserRegisterd = async (e: any) => {
    e.preventDefault();
    try {
      let pagePrefix = "phone";
      let pageSuffix = "Register";
      let page = pagePrefix + pageSuffix;

      if (!state || !state.username || !state.username.trim()) {
        return setMSG(languages[language.language.short].loginPage.s11);
      }

      const {
        country,
        countryCallingCode: countryCode,
        nationalNumber,
      } = parsePhoneNumber(state.username) as {
        country: string;
        countryCallingCode: string;
        nationalNumber: string;
      };

      if (!country) {
        return setMSG("کد کشور صحیح نیست");
      }

      // if (validator.isEmail(state.username)) {
      //   pagePrefix = "email";
      //   page = pagePrefix + pageSuffix;
      //   // go to login page with email and password
      //   // set page login by email
      // }
      // // api call

      const isUserRegistered = await axios.get(
        `/api/user/is-verified?username=${countryCode + "-" + nationalNumber}`
      );

      if (isUserRegistered.data.success) {
        // send otp password and go to login page
        pageSuffix = "Login";
        page = pagePrefix + pageSuffix;
      }

      setState({ ...state, page });

      if (page === "phoneLogin") {
        startCountDown();

        setTimeout(() => {
          inputRef.current?.focus();
        }, 10);

        axios.post("/api/forget-password", {
          username: `${countryCode + "-" + nationalNumber}`,
        });
      }

      setMSG("");

      // {
      //   "customer": {
      //       "name": "sdf",
      //       "phoneNumber": "09031203034",
      //       "password": "123456789"
      //   },
      //   "confirmPassword": "123456789"
      // }

      if (page === "phoneRegister") {
        //sign-up
        /* +countryCode
        username concat
        */
        const password = randomstring.generate();

        await axios.put("/api/customer/sign-up-otp", {
          countryCode,
          phoneNumber: `${countryCode + "-" + nationalNumber}`,
        });

        await axios.post("/api/customer/send-sms", {
          username: `${countryCode + "-" + nationalNumber}`,
        });
        inputRef.current?.focus();
      }
    } catch (error: any) {
      const { data } = error.response;
      console.log(data);
    }
  };

  const otpLogin = async (e: any) => {
    e.preventDefault();
    try {
      if (!state || !state.username || !state.username.trim()) {
        return setMSG(languages[language.language.short].loginPage.s11);
      }

      const {
        country,
        countryCallingCode: countryCode,
        nationalNumber,
      } = parsePhoneNumber(state.username) as {
        country: string;
        countryCallingCode: string;
        nationalNumber: string;
      };

      if (!country) {
        return setMSG("کد کشور صحیح نیست");
      }

      if (state.page === "phoneRegister") {
        const res = await axios.post("/api/customer/phone-verify", {
          verificationCode: state.otpPassword,
          username: `${countryCode + "-" + nationalNumber}`,
        });
      } else {
        const res = await axios.post("/api/login/otp", {
          username: `${countryCode + "-" + nationalNumber}`,
          verificationCode: state.otpPassword,
          rememberMe: state.rememberMe,
        });
      }
      iClearInterval();
      navigate("/home");
    } catch (error) {
      setMSG(languages[language.language.short].loginPage.s12);
      console.log(error);
    }
  };

  const [captcha, setCaptcha] = useState<string>("");
  const [captchaId, setCaptchaId] = useState<string>("");
  const [captchaCode, setCaptchaCode] = useState<string>("");

  const regenerateCaptcha = async () => {
    const res: any = await axios.get("/api/captcha").catch((error: any) => {});
    setCaptcha("data:image/jpeg;base64," + res.data.captcha);
    setCaptchaId(res.data.id);
  };

  const login = async (e: any) => {
    e.preventDefault();
    setMSG(messages.wait);
    if (msg === messages.wait) return;

    try {
      const res: any = await axios.post(
        "/api/login",
        {
          password: state.password,
          username: state.username,
          rememberMe: state.rememberMe,
        }
        // {
        //   headers: {
        //     // Add your custom headers here
        //     Host: "moneasy.ir",
        //     "Content-Type": "application/json",
        //     "Captcha-Response": captchaCode,
        //     "Captcha-Id": captchaId,
        //     Captchaheadername: null,
        //   },
        // }
      );
      iClearInterval();
      navigate("/home");
    } catch (error: any) {
      const { data } = error.response;
      switch (data) {
        case "Captcha validation failed":
          setMSG(messages.captchaFail);
          break;
        case "Wrong username or password":
          setMSG(messages.wrongPassword);
          break;
        case "LOGIN SUCCESSFUL":
          break;
        default:
          setMSG(messages.fail);
      }
    }

    regenerateCaptcha();
  };

  useEffect(() => {
    (async () => {
      try {
        const myRes: any = await axios.get("/api/current/user");

        if (typeof myRes !== "string") {
          return navigate("/home");
        }
      } catch (error) {}

      const res: any = await axios
        .get("/api/captcha", {
          // headers: {
          //   // Add your custom headers here
          //   Host: "moneasy.ir",
          //   "Content-Type": "application/json",
          //   Origin: "moneasy.ir",
          //   // Other headers if needed
          // },
        })
        .catch((error: any) => {
          console.log(error);
        });
      setCaptcha("data:image/jpeg;base64," + res.data.captcha);
      setCaptchaId(res.data.id);
    })();
  }, []);

  const [countDown, setCountDown] = useState(120);

  const startCountDown = async () => {
    // const {
    //   country,
    //   countryCallingCode: countryCode,
    //   nationalNumber,
    // } = parsePhoneNumber(state.username) as {
    //   country: string;
    //   countryCallingCode: string;
    //   nationalNumber: string;
    // };

    // console.log(countryCode);
    // console.log(nationalNumber);

    // console.log("###############");
    // const myTime = await axios.get(
    //   "/api/user/time-to-resend-code?username=" +
    //     countryCode +
    //     "-" +
    //     nationalNumber
    // );
    // console.log(myTime);
    // console.log("###############");
    interval = setInterval(function () {
      if (countDown === 0) {
        return iClearInterval();
      }

      setCountDown((currentCountdown) => currentCountdown - 1);
    }, 1000);
  };

  const iClearInterval = () => {
    clearInterval(interval);
    interval = null;
  };

  useEffect(() => {
    if (countDown <= 0) {
      iClearInterval();
    }
  }, [countDown]);

  const openLanguagesBox = (e: any) => {
    e.currentTarget.nextElementSibling.classList.toggle("hidden");
  };
  const dispatch = useDispatch();
  const changeLanguageFunc = (e: any, lng: any) => {
    e.currentTarget.parentElement.classList.toggle("hidden");
    dispatch(
      changeLanguage({
        short: lng,
        rtl: !!languages[lng].rtl,
      })
    );
  };
  const [phone, setPhone] = useState("");

  useEffect(() => {
    setState({ ...state, username: phone });
  }, [phone]);

  const countries = defaultCountries.filter((country: any) => {
    const { iso2 } = parseCountry(country);
    if (moneasyConfig.international) {
      return !["il"].includes(iso2);
    }
    return ["ir"].includes(iso2);
  });

  return (
    <div className={language.language.rtl ? "rtl-direction w-full" : "w-full"}>
      <div
        className={
          state.page === "isRegistered"
            ? "flex justify-center center items-center fixed inset-y-0 inset-x-0 "
            : "hidden"
        }
      >
        <div className="max-w-md rlt-dir w-11/12 mx-auto  md:border md:border-gray-200 p-6 md:rounded-lg">
          <form onSubmit={checkIsUserRegisterd}>
            <div className="text-center w-full  p-2 relative">
              <img src={logo} alt="مانیزی" className="mx-auto mt-5" />
            </div>
            <div className="mb-6 ">
              <div className="w-full flex justify-between">
                <h2 className="mb-6 mt-3 text-2xl text-gray-700">
                  {languages[language.language.short].loginPage.s1}
                </h2>
                <div className="flex text-2xl  items-center text-gray-700 relative">
                  <FaLanguage
                    className="m-1 cursor-pointer"
                    onClick={openLanguagesBox}
                  />

                  <div
                    className={
                      language.language.rtl
                        ? "absolute bg-gray-600 top-6 left-9 text-lg text-center flex flex-col p-2 rounded-lg hidden"
                        : "absolute bg-gray-600 top-6 right-9 text-lg text-center flex flex-col p-2 rounded-lg hidden"
                    }
                  >
                    {Object.values(languages).map((lng: any) => (
                      <span
                        className="block cursor-pointer text-gray-200 hover:text-white"
                        onClick={(e) => changeLanguageFunc(e, lng["short"])}
                      >
                        {lng["language"]}
                      </span>
                    ))}
                  </div>
                </div>
              </div>

              {/* <label
                htmlFor="success"
                className="block mb-4 text-sm font-medium text-gray-500"
              >
                کشور
              </label> */}
              {/* <input
                type="text"
                className="ltr-direction text-center tracking-wide bg-white border border-gray-300 w-full p-2.5 block text-blue-900 placeholder-gray-500 focus:placeholder-gray-600 text-sm rounded-lg focus:outline-none focus:ring focus:ring-blue-500"
                placeholder="کشور"
                onChange={(e) =>
                  setState({ ...state, username: e.target.value })
                }
                value={state.username}
              /> */}
              <label
                htmlFor="success"
                className="block mb-4 text-sm font-medium text-gray-500 mt-4"
              >
                {languages[language.language.short].loginUserNameTitle}
              </label>
              {/* <PhoneInput
                international
                defaultCountry="IR"
                flags={flags}
                value={value}
                onChange={setValue}
                autoFocus={true}
              /> */}

              <PhoneInput2
                defaultCountry="ir"
                autoFocus={true}
                value={phone}
                countries={countries}
                onChange={(phone: any) => setPhone(phone)}
              />

              <div className="flex items-center gap-2 text-gray-700 mt-2">
                <span
                  className="flex gap-2 items-center cursor-pointer"
                  onClick={() =>
                    setState({ ...state, rememberMe: !state.rememberMe })
                  }
                >
                  {languages[language.language.short].loginPage.s15}{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="20"
                    height="20"
                    viewBox="0 0 50 50"
                    className={
                      state.rememberMe ? "fill-black cursor-pointer" : "hidden"
                    }
                  >
                    <path d="M 11 4 C 7.101563 4 4 7.101563 4 11 L 4 39 C 4 42.898438 7.101563 46 11 46 L 39 46 C 42.898438 46 46 42.898438 46 39 L 46 15 L 44 17.3125 L 44 39 C 44 41.800781 41.800781 44 39 44 L 11 44 C 8.199219 44 6 41.800781 6 39 L 6 11 C 6 8.199219 8.199219 6 11 6 L 37.40625 6 L 39 4 Z M 43.25 7.75 L 23.90625 30.5625 L 15.78125 22.96875 L 14.40625 24.4375 L 23.3125 32.71875 L 24.09375 33.4375 L 24.75 32.65625 L 44.75 9.03125 Z"></path>
                  </svg>
                  <FaRegSquare
                    className={
                      !state.rememberMe
                        ? "text-xl text-gray-400  cursor-pointer"
                        : "hidden"
                    }
                  />
                </span>

                {/* <input type="checkbox" /> */}
              </div>

              {/* <label
                htmlFor="success"
                className="block mb-4 text-sm font-medium text-gray-500 mt-4"
              >
                {languages[language.language.short].loginUserNameTitle}
              </label> */}

              {/* <input
                type="text"
                className="ltr-direction text-center mt-4 tracking-wide bg-white border border-gray-300 w-full p-2.5 block text-blue-900 placeholder-gray-500 focus:placeholder-gray-600 text-sm rounded-lg focus:outline-none focus:ring focus:ring-blue-500"
                placeholder={
                  languages[language.language.short].loginUserNamePlaceHolder
                }
                onChange={(e) =>
                  setState({ ...state, username: e.target.value })
                }
                value={state.username}
              /> */}

              <p className="text-sm text-red-500  mt-2 mb-4">
                <span className="font-medium">{msg}</span>
              </p>
              <div className="flex mt-1  flex-col">
                <button
                  type="submit"
                  className="text-white bg-teal-500 border hover:bg-teal-600 hover:text-white  rounded-lg text-sm px-5 py-2.5 me-2 mb-2  w-full"
                >
                  {languages[language.language.short].continue}
                </button>
                <p className="text-sm text-center mt-2">
                  {languages[language.language.short].s2}
                  <span>
                    {languages[language.language.short].loginPage.s2}
                  </span>{" "}
                  <span>{languages[language.language.short].loginPage.s4}</span>{" "}
                </p>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div
        className={
          state.page === "phoneLogin" || state.page === "phoneRegister"
            ? "flex justify-center center items-center fixed inset-y-0 inset-x-0"
            : "hidden"
        }
      >
        <div className="max-w-md rlt-dir w-11/12 mx-auto  md:border md:border-gray-200 p-6 md:rounded-lg relative">
          <div className="text-center w-full p-2 relative">
            <img src={logo} alt="مانیزی" className="mx-auto mt-5" />
            <span
              className={
                language.language.rtl
                  ? "top-1/2 right-0 absolute cursor-pointer"
                  : "top-1/2 left-0 absolute cursor-pointer"
              }
              onClick={() => {
                setState({
                  ...state,
                  page: "isRegistered",
                  otpPassword: "",
                });
                setCountDown(120);
                iClearInterval();
                setMSG("");
              }}
            >
              {language.language.rtl ? <FaArrowRight /> : <FaArrowLeft />}
            </span>
          </div>
          <div className="mb-6">
            <h2 className="mb-6 mt-3 text-2xl text-gray-700">
              {languages[language.language.short].loginPage.s7}
            </h2>
            <p className="text-gray-700 text-sm mb-6">
              {languages[language.language.short].loginPage.s8}{" "}
              {languages[language.language.short] === "fa"
                ? transform.engNumberToPersian(state.username)
                : state.username}{" "}
              {languages[language.language.short].loginPage.s9}
            </p>
            <form onSubmit={otpLogin}>
              <label
                htmlFor="success"
                className="block mb-2 text-sm font-medium text-gray-500"
              >
                {languages[language.language.short].confirmationCode}
              </label>
              <input
                type="tel"
                onFocus={(e) => (e.target.placeholder = "")}
                onBlur={(e) => (e.target.placeholder = "XXXXXX")}
                maxLength={6}
                onKeyUp={(e) => {
                  if (state.otpPassword.length >= 6) {
                    e.currentTarget.blur();
                    console.log(state);
                    try {
                      otpLogin(e);
                    } catch (err) {
                      try {
                      } catch (err) {
                        login(e);
                      }
                    }
                  }
                }}
                className="text-center ltr-direction bg-white border border-gray-300 w-full p-2.5 block text-blue-900 placeholder-gray-500 focus:placeholder-gray-600 text-sm rounded-lg focus:outline-none focus:ring focus:ring-blue-500 letterWidest"
                placeholder={
                  languages[language.language.short].confirmationCodePlaceHolder
                }
                onChange={(e) => {
                  setState({ ...state, otpPassword: e.target.value });
                }}
                value={state.otpPassword}
                ref={inputRef}
              />
              <p className="text-sm ">
                <span className="font-medium">{msg}</span>
              </p>

              <div className="my-8"></div>

              <p className="text-center text-sm text-gray-500">
                {`0${(countDown - (countDown % 60)) / 60}:${
                  ((countDown % 60) - ((countDown % 60) % 10)) / 10
                }${(countDown % 60) % 10}`}{" "}
                {languages[language.language.short].loginPage.s10}
              </p>

              <div className="flex mt-1 md:flex-row flex-col">
                <button
                  type="submit"
                  className="text-white border border-teal-500 bg-teal-400 hover:bg-teal-600 hover:text-white  rounded-lg text-sm px-5 py-2.5 me-2 mb-2 w-full"
                >
                  {languages[language.language.short].signin}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div
        className={
          state.page === "emailLogin"
            ? "flex justify-center center items-center fixed inset-y-0 inset-x-0   "
            : "hidden"
        }
      >
        <div className="max-w-md rlt-dir w-11/12 mx-auto md:shadow-xl shadow-gray-300  md:rounded-lg p-4">
          <div className="text-center w-full  p-2 relative">
            <img src={logo} alt="مانیزی" className="mx-auto mt-5" />
            <span
              className="top-1/2 right-0 absolute cursor-pointer"
              onClick={() => {
                setState({
                  ...state,
                  page: "isRegistered",
                  otpPassword: "",
                });
                iClearInterval();
                setCountDown(119);
                setMSG("");
              }}
            >
              <FaArrowRight />
            </span>
          </div>
          <div className="mb-6">
            <form onSubmit={login}>
              <label
                htmlFor="success"
                className="block mb-2 text-sm font-medium text-gray-500 mt-8"
              >
                {languages[language.language.short].loginPasswordTitle}
              </label>
              <input
                type="password"
                id="success2"
                className="bg-white border border-gray-300 w-full p-2.5 block text-blue-900 placeholder-gray-500 focus:placeholder-gray-600 text-sm rounded-lg focus:outline-none focus:ring focus:ring-blue-500"
                placeholder={
                  languages[language.language.short].loginPasswordPlaceHolder
                }
                onChange={(e) =>
                  setState({ ...state, password: e.target.value })
                }
                value={state.password}
              />
              <div className="w-full flex items-center text-lg mt-8">
                <img src={captcha} alt="کپتچا" />
                <FaArrowRotateRight
                  className="mr-1 cursor-pointer"
                  onClick={regenerateCaptcha}
                />
              </div>
              <label
                htmlFor="success"
                className="block mb-2 text-sm font-medium text-gray-500 mt-4"
              >
                {languages[language.language.short].captcha}
              </label>
              <input
                type="text"
                id="success3"
                className="bg-white border border-gray-300 w-full p-2.5 block text-blue-900 placeholder-gray-500 focus:placeholder-gray-600 text-sm rounded-lg focus:outline-none focus:ring focus:ring-blue-500"
                placeholder={languages[language.language.short].captcha}
                onChange={(e) => setCaptchaCode(e.target.value)}
                value={captchaCode}
              />
              <div className="my-8"></div>
              <p className="text-sm text-red-500 ">
                <span className="font-medium">{msg}</span>
              </p>
              <div className="flex mt-1 md:flex-row flex-col">
                <button
                  type="submit"
                  className="border  hover:bg-gray-100 hover:text-teal-500 hover:border-teal-500 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 text-white bg-teal-500    w-full"
                >
                  {languages[language.language.short].signin}
                </button>

                <button
                  onClick={() => {
                    setState({ ...state, page: "isRegistered" });
                    iClearInterval();
                    setCountDown(120);
                    setMSG("");
                  }}
                  type="button"
                  className="text-teal-500 bg-white border border-teal-500  hover:bg-teal-500 hover:text-white  rounded-lg text-sm px-5 py-2.5 me-2 mb-2  w-full"
                >
                  {languages[language.language.short].correct}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* <div
        className={
          state.page === "phoneRegister"
            ? "flex justify-center center items-center fixed inset-y-0 inset-x-0"
            : "hidden"
        }
      >
        کاربر یافت نشد.
      </div> */}
    </div>
  );
}
