class PersianPricePipe {
  transform(val: any) {
    if (val) {
      const eng = this.persianNumberToEng(val as string);
      const comma = this.format_number(eng, "");
      const per = this.engNumberToPersian(comma as string);
      val = this.addToman(per as string);
    }
    return val;
  }

  format_number(number: any, prefix: any) {
    let thousand_separator = ",",
      negativeSign = "-",
      regex = new RegExp("[^" + negativeSign + "\\d]", "g"),
      number_string = number.replace(regex, "").toString(),
      split = number_string.split(negativeSign),
      value = split[0] + split[1],
      rest = value.length % 3,
      result = value.substr(0, rest),
      thousands = value.substr(rest).match(/\d{3}/g);
    if (thousands) {
      let separator = rest ? thousand_separator : "";
      result += separator + thousands.join(thousand_separator);
    }
    result = split[1] !== undefined ? result + negativeSign : result;
    return prefix === undefined ? result : result ? prefix + result : "";
  }

  persianNumberToEng(number: any) {
    if (number == undefined) return NaN;
    let str = number.toString().trim();
    if (str === "") return NaN;
    str = str.replace(/۰/g, "0");
    str = str.replace(/۱/g, "1");
    str = str.replace(/۲/g, "2");
    str = str.replace(/۳/g, "3");
    str = str.replace(/۴/g, "4");
    str = str.replace(/۵/g, "5");
    str = str.replace(/۶/g, "6");
    str = str.replace(/۷/g, "7");
    str = str.replace(/۸/g, "8");
    str = str.replace(/۹/g, "9");
    return str;
  }

  engNumberToPersian(number: string): string {
    if (number == undefined) return "";
    let str = number.toString().trim();
    if (str === "") return "";
    str = str.replace(/0/g, "۰");
    str = str.replace(/1/g, "۱");
    str = str.replace(/2/g, "۲");
    str = str.replace(/3/g, "۳");
    str = str.replace(/4/g, "۴");
    str = str.replace(/5/g, "۵");
    str = str.replace(/6/g, "۶");
    str = str.replace(/7/g, "۷");
    str = str.replace(/8/g, "۸");
    str = str.replace(/9/g, "۹");
    return str;
  }

  addToman(number: string): string {
    if (number == undefined) return "";
    const str = number.toString().trim();
    if (str === "") return "";
    // str = str + ' تومان';
    return str;
  }

  convertMoney(money: string, rtl?: boolean) {
    return typeof rtl === "boolean" && rtl
      ? this.engNumberToPersian(this.format_number(money + "", ""))
      : this.format_number(money + "", "");
  }
}

export const transform = new PersianPricePipe();

export default PersianPricePipe;
