import { useEffect, useState } from "react";
import FileHeader from "./FileHeader";
import { useDispatch, useSelector } from "react-redux";
import { nationalCardUploadMSG } from "../../redux/docSlice";
import languages from "../../languages/languages";

export interface SingleFileUploadWithProgressProps {
  file: File;
  onDelete: (file: File) => void;
  onUpload: (url: string) => void;
  name: string;
}
export function SingleFileUploadWithProgress({
  file,
  onDelete,
  onUpload,
  name,
}: SingleFileUploadWithProgressProps) {
  const language = useSelector((state: any) => state.language);

  const dispatch = useDispatch();
  const [progress, setProgress] = useState(0);
  const [msg, setMSG] = useState("");
  useEffect(() => {
    setMSG("");
    async function upload() {
      const result: any = await uploadFile(
        file,
        setProgress,
        showNationalCardBox,
        name
      );
      console.log("#######################");
      console.log(result);
      console.log("#######################");
      if (result.status === "success") {
        return onUpload(result.url);
      }

      setMSG(languages[language.language.short].contact.s3);

      setProgress(0);
    }
    upload();
  }, [file]);

  const showNationalCardBox = (lng: any) => {
    dispatch(nationalCardUploadMSG("showNationalCardBox"));
  };

  return (
    <>
      <FileHeader file={file} onDelete={onDelete} />{" "}
      <p className="text-red-500 mt-3 ">{msg}</p>
      <div className=" w-full h-2 bg-gray-200 rounded-full dark:bg-gray-700">
        <div
          className="h-2 bg-blue-600 rounded-full dark:bg-blue-500 "
          style={{ width: `${progress}%` }}
        ></div>
      </div>
      <img id="outputImage" src="" />
    </>
  );
}

function uploadFile(
  file: File,
  onProgress: (pecentage: number) => void,
  showNationalCardBox: any,
  name: string
) {
  return new Promise<string>((res: any, rej) => {
    let url = "/api/documents/upload";

    let imgURL = "/portal/external/Documents";

    switch (name) {
      case "nationalFront":
        url += "/national-card";
        imgURL += "/national-card";
        break;
      case "nationalBack":
        url += "/national-card-back";
        imgURL += "/national-card-back";
        break;
      case "birthCertificate":
        url += "/birth-certificate";
        imgURL += "/birth-certificate";
        break;

      case "passport":
        url += "/passport";
        imgURL += "/passport";
        break;

      case "foreignCard":
        url += "/foreign-card";
        imgURL += "/foreign-card";
        break;
      case "passportForeign":
        url += "/passport";
        imgURL += "/passport";
        break;
      case "manifest":
        url += "/company-statute";
        imgURL += "/company-statute";
        break;
      case "newspaper":
        url += "/company-signatories";
        imgURL += "/company-signatories";
        break;

      case "foundedAd":
        url += "/notice-of-establishment";
        imgURL += "/notice-of-establishment";
        break;
      case "stock":
        url += "/shareholders-info";
        imgURL += "/shareholders-info";
        break;
    }
    /////////////////////////////////////
    const reader = new FileReader();
    reader.onload = function (e: any) {
      const img: any = new Image();
      img.src = e.target.result;

      img.onload = function () {
        const canvas = document.createElement("canvas");
        const ctx: any = canvas.getContext("2d");

        let quality = 0.9; // Initial quality
        let outputBlob;

        const resizeAndCompress = () => {
          const maxWidth = 800;
          const maxHeight = 600;
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > maxWidth) {
              height *= maxWidth / width;
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width *= maxHeight / height;
              height = maxHeight;
            }
          }

          canvas.width = width;
          canvas.height = height;

          ctx.drawImage(img, 0, 0, width, height);

          canvas.toBlob(
            (blob: any) => {
              outputBlob = blob;

              if (blob.size > 300 * 1024 && quality > 0.1) {
                quality -= 0.1;
                resizeAndCompress();
              } else {
                const compressedFile = new File([blob], file.name, {
                  type: file.type,
                });

                console.log(`Original size: ${file.size} bytes`);
                console.log(`Compressed size: ${compressedFile.size} bytes`);
                const formData = new FormData();
                formData.append("upload", compressedFile);
                const xhr = new XMLHttpRequest();

                xhr.open("POST", url, true);

                xhr.onload = () => {
                  if (xhr.status === 200) {
                    console.log(xhr);

                    showNationalCardBox("showNationalCardBox");

                    res({
                      status: "success",
                      url: imgURL + "/" + xhr.responseText,
                    });
                  } else {
                    showNationalCardBox("hiddenNationalCardBox");
                    res({
                      status: "failed",
                      message: xhr.responseText,
                    });
                  }
                };

                xhr.onerror = (evt) => rej(evt);

                xhr.upload.onprogress = (event) => {
                  if (event.lengthComputable) {
                    const percentage = (event.loaded / event.total) * 100;
                    onProgress(Math.round(percentage));
                  }
                };

                xhr.send(formData);
              }
            },
            "image/jpeg",
            quality
          );
        };

        resizeAndCompress();
      };
    };

    reader.readAsDataURL(file);
  });
}
