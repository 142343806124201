import { Transition } from "react-transition-group";
import { useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FaCircleInfo, FaMixer } from "react-icons/fa6";
import { changeLightBoxState, setPinDet } from "../../redux/lightboxSlice";
import { Link } from "react-router-dom";
import { FaMobileButton } from "react-icons/fa6";
import axios from "axios";

const duration = 150;

const transitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};
export function SlideBottom() {
  const dispatch = useDispatch();

  const { lightboxState, showUploadDocs, accountId, level, pinDet } =
    useSelector((state: any) => state.lightbox);

  // const [lightboxState, setInProp] = useState(false);
  const nodeRef = useRef(null);

  const [state, setState] = useState({ code: "", pinCode: "", msg: "" });

  const changeLevel = async (e: any) => {
    e.preventDefault();

    if (level === 0) {
      try {
        const response = axios.post(
          "/api/userBankAccount/request/level1/validate-verification-code",
          {
            code: state.code,
            accountId,
          }
        );

        setTimeout(() => {
          setState({ ...state, msg: "با موفقیت انجام شد" });
        }, 2000);
      } catch (error) {
        setState({ ...state, msg: "کد تایید اشتباه است" });
      }
      return;
    }
  };

  const setPincode = () => {
    dispatch(setPinDet({ msg: "", pin: state.pinCode, aware: Date.now() }));
  };

  return (
    <div
      onClick={() => dispatch(changeLightBoxState(false))}
      className={
        lightboxState
          ? "cursor-pointer fixed inset-0 bg-black bg-opacity-80 z-50 flex justify-center items-end rtl-direction"
          : "hidden"
      }
    >
      <Transition nodeRef={nodeRef} in={lightboxState} timeout={duration}>
        {(lightboxState) => (
          <div
            onClick={(e) => e.stopPropagation()}
            className={`p-1 py-2 text-right fade fade-${lightboxState}`}
          >
            <p
              className={level === "pinCode" ? "flex justify-center" : "hidden"}
              style={{ direction: "rtl" }}
            >
              <span className="px-1">
                <FaCircleInfo size={20} />
              </span>
              پین کد خود را وارد نماییید
            </p>

            <p
              className={level === 0 ? "flex" : "hidden"}
              style={{ direction: "rtl" }}
            >
              <span className="px-1">
                <FaCircleInfo size={20} />
              </span>
              <span>
                کاربر گرامی به منظور افزایش سطح تایید اعتبار، مدارک هویتی باید
                بارگذاری شود.
                <Link
                  onClick={() => dispatch(changeLightBoxState(false))}
                  to="/doc/add-doc"
                  className="text-orange-500"
                >
                  {" "}
                  برای آپلود اینجا کلیک
                </Link>{" "}
                نمایید.
              </span>
            </p>
            <p
              className={level === 1 ? "flex justify-center" : "hidden"}
              style={{ direction: "rtl" }}
            >
              <span className="px-1">
                <FaCircleInfo size={20} />
              </span>
              <span>
                کاربر گرامی افزایش سطح برداشت ثبت شد. لطفا منتظر تایید باشید.
              </span>
            </p>
            <div
              className={
                level === "pinCode" ? "flex justify-center flex-col" : "hidden"
              }
            >
              <div className="bg-white border mt-8 border-gray-300 w-11/12 flex items-center ltr-direction text-blue-900 placeholder-gray-500 focus:placeholder-gray-600 text-sm rounded-lg focus:outline-none focus:ring focus:ring-blue-500 max-w-md mx-auto radious">
                <div className="p-2">
                  <FaMixer className="text-2xl" />
                </div>

                <input
                  type="text"
                  onFocus={(e) => (e.target.placeholder = "")}
                  onBlur={(e) => (e.target.placeholder = "XXXXX")}
                  onChange={(e) =>
                    setState({ ...state, pinCode: e.target.value })
                  }
                  maxLength={5}
                  className="text-center block mx-auto ltr-direction bg-white  w-full max-w-96 p-2.5  text-blue-900 placeholder-gray-500 focus:placeholder-gray-600 text-sm rounded-lg focus:outline-none focus:ring focus:ring-blue-500 letterWidest"
                  placeholder="XXXXX"
                  value={state.pinCode}
                />
              </div>

              <div className="flex flex-col mt-5 w-full justify-center items-center">
                {state.msg} {pinDet.msg}
                <button
                  type="button"
                  onClick={setPincode}
                  className="border hover:text-white font-medium rounded-lg text-sm px-5 py-2.5 mb-2 border-teal-500  hover:bg-teal-500 text-teal-500  w-full max-w-md mx-auto "
                >
                  تایید
                </button>
                {/* <button
                  type="button"
                  onClick={() => dispatch(changeLightBoxState(false))}
                  className="text-teal-500 bg-white border border-teal-500  hover:bg-teal-500 hover:text-white  rounded-lg text-sm px-5 py-2.5 me-2 mb-2  w-full max-w-96"
                >
                  عدم تایید
                </button> */}
              </div>
            </div>
            <div
              className={
                level === "increaseCode" ? "flex justify-center" : "hidden"
              }
            >
              <form onSubmit={changeLevel}>
                <label
                  htmlFor="success"
                  className="flex py-6 justify-center mb-2 text-sm font-medium text-gray-500 text-center"
                >
                  <FaMobileButton />
                  کد تایید افزایش اعتبار برداشت به تلفن همراه شما ارسال شد:
                </label>
                <input
                  type="text"
                  onFocus={(e) => (e.target.placeholder = "")}
                  onBlur={(e) => (e.target.placeholder = "XXXXXX")}
                  onChange={(e) => setState({ ...state, code: e.target.value })}
                  maxLength={6}
                  className="text-center block mx-auto ltr-direction bg-white border border-gray-300 w-full max-w-96 p-2.5  text-blue-900 placeholder-gray-500 focus:placeholder-gray-600 text-sm rounded-lg focus:outline-none focus:ring focus:ring-blue-500 letterWidest"
                  placeholder="XXXXXX"
                  value={state.code}
                />
                <div className="flex flex-col mt-5 w-full justify-center items-center">
                  {state.msg}
                  <button
                    type="submit"
                    className="border hover:text-white font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 border-teal-500  hover:bg-teal-500 text-teal-500  w-full max-w-96"
                  >
                    تایید
                  </button>
                  <button
                    type="button"
                    onClick={() => dispatch(changeLightBoxState(false))}
                    className="text-teal-500 bg-white border border-teal-500  hover:bg-teal-500 hover:text-white  rounded-lg text-sm px-5 py-2.5 me-2 mb-2  w-full max-w-96"
                  >
                    عدم تایید
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </Transition>
    </div>
  );
}
