import { FaCheck, FaMoneyBill1, FaPencil } from "react-icons/fa6";
import LayoutMain from "../../components/LayoutMain";
import messages from "../../utils/messages";

import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import languages from "../../languages/languages";
import { changeLightBoxState, setLevel } from "../../redux/lightboxSlice";
import { transform } from "../../utils/persianTransform";

export default function Charge() {
  const language = useSelector((state: any) => state.language);
  const dispatch = useDispatch();

  const [user, setUser] = useState<any>({
    username: "",
    roles: [],
  });

  const [msg, setMSG] = useState("");

  const [state, setState] = useState({
    filledAmount: "",
    description: "",
    account: "اصلی",
  });

  const fetchData = async () => {
    try {
      let userRes: any = await axios.get("/api/current/user");

      setUser(userRes.data);
    } catch (error) {}
  };

  useEffect(() => {
    fetchData();
  }, []);

  const goBank = async () => {
    let filledAmount = state.filledAmount
      ? transform.persianNumberToEng(state.filledAmount)
      : "0";
    filledAmount = +filledAmount.replace(/[^0-9]/g, "");
    if (msg === messages.wait) {
      return;
    }

    if (filledAmount < 10000) {
      return setMSG(languages[language.language.short].chargePage.s4);
    }

    if (state.account !== "اصلی") {
      return setMSG(
        `${
          languages[language.language.short].chargePage.s4 +
          " " +
          state.account +
          " " +
          languages[language.language.short].chargePage.s6
        } `
      );
    }

    setMSG(messages.wait);
    try {
      const res = await axios.post("/api/ipg/iec", {
        username: user.username,
        amount: filledAmount,
        description: state.description,
      }); /*************** */
      // {transaction_id: '9516761d3aa520f23', iecURL: 'https://pec.shaparak.ir/NewPG/pay', sign: 'EB0DE0CFEE02F583E254EFD6424EACB77AA8ADF303A0BA40E1C5EBB94A848A57'
      var form = document.createElement("form");
      var element1 = document.createElement("input");
      var element2 = document.createElement("input");

      form.method = "POST";
      form.action = res.data.iecURL;

      element1.value = res.data.transaction_id;
      element1.name = "transaction_id";
      element1.type = "hidden";
      form.appendChild(element1);

      element2.value = res.data.sign;
      element2.name = "sign";
      element2.type = "hidden";
      form.appendChild(element2);

      document.body.appendChild(form);

      form.submit();
    } catch (error: any) {
      const { data } = error.response;
      if (
        data ===
        "این تراکنش سقف شارژ روزانه را رد می کند. در صورت عدم تکمیل مدارک، می توانید با ثبت اطلاعات هویتی و سپس تکمیل مدارک ، سقف برداشت روزانه خود را افزایش دهید."
      ) {
        dispatch(changeLightBoxState(true));

        dispatch(setLevel(0));
        setMSG("");
      }
    }
  };

  const [unitsList, setUnitsList] = useState<any>([]);
  useEffect(() => {
    (async () => {
      try {
        const { data }: any = await axios.get("/api/alt-account/customer-list");
        console.log([...unitsList, ...data].slice(0, 3));
        setUnitsList([...unitsList, ...data].slice(0, 3));
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const renderAccountUnit = (unit: string) => {
    let transferUnitPersian =
      languages[language.language.short].dynamicWords.tomanAccountUnit;
    switch (unit) {
      case "تومان":
        transferUnitPersian =
          languages[language.language.short].dynamicWords.tomanAccountUnit;
        break;
      case "دلار":
        transferUnitPersian =
          languages[language.language.short].dynamicWords.dollarAccountUnit;
        break;
      case "درهم":
        transferUnitPersian =
          languages[language.language.short].dynamicWords.dirhamAccountUnit;
        break;
      case "یورو":
        transferUnitPersian =
          languages[language.language.short].dynamicWords.euroAccountUnit;
    }
    return transferUnitPersian;
  };

  const [transferType, setTransferType] = useState("0");
  let transferUnitPersian =
    languages[language.language.short].dynamicWords.tomanAccountUnit;
  switch (transferType) {
    case "0":
      transferUnitPersian =
        languages[language.language.short].dynamicWords.tomanAccountUnit;
      break;
    case "113":
      transferUnitPersian =
        languages[language.language.short].dynamicWords.dollarAccountUnit;
      break;
    case "114":
      transferUnitPersian =
        languages[language.language.short].dynamicWords.euroAccountUnit;
      break;
    case "115":
      transferUnitPersian =
        languages[language.language.short].dynamicWords.dirhamAccountUnit;
  }

  const [showBankPhysical, setShowBankPhysical] = useState<any>({
    paymentId: "",
    amount: 0,
    msg: "",
  });
  const goBankPhysical = async () => {
    let filledAmount = state.filledAmount
      ? transform.persianNumberToEng(state.filledAmount)
      : "0";
    filledAmount = +filledAmount.replace(/[^0-9]/g, "");
    console.log(filledAmount);

    if (filledAmount < 5000) {
      return setShowBankPhysical({
        ...showBankPhysical,
        msg: languages[language.language.short].chargePage.s7,
      });
    }
    if (showBankPhysical.msg === messages.wait) return;
    setShowBankPhysical({ ...showBankPhysical, msg: messages.wait });

    try {
      const { data } = await axios.post("/api/charge/cash/request", {
        username: user.username,
        amount: filledAmount,
        description: state.description,
      });

      setShowBankPhysical({
        ...showBankPhysical,
        msg: `${
          languages[language.language.short].chargePage.s8
        } ${transform.convertMoney(data.data.amount)}${
          languages[language.language.short].chargePage.s9
        } <span class="bg-orange-400 p-1">${data.data.paymentId}</span> ${
          languages[language.language.short].chargePage.s10
        }`,
      });
    } catch (error) {
      console.log(error);
      setShowBankPhysical({
        ...showBankPhysical,
        msg: "خطایی رخ داده لطفا تب نت ورک را مشاهده نمایید.",
      });
    }
  };

  const [showToman, setShowToman] = useState("");

  const amountFunc = (e: any) => {
    let amount;
    try {
      amount = transform.persianNumberToEng(e.target.value);

      amount = amount.replace(/[^0-9]/g, "");
    } catch (e: any) {
      amount = "";
    }

    if (languages[language.language.short].rtl) {
    }

    setState({
      ...state,
      filledAmount: amount
        ? languages[language.language.short].rtl
          ? transform.engNumberToPersian(transform.convertMoney(amount))
          : transform.convertMoney(amount)
        : "",
    });
  };

  useEffect(() => {}, [state.filledAmount]);

  const amountFunc2 = () => {
    let amount;
    try {
      amount = transform.persianNumberToEng(state.filledAmount);

      amount = amount.replace(/[^0-9]/g, "");
    } catch (e: any) {
      amount = "";
    }

    setState({
      ...state,
      filledAmount: amount
        ? languages[language.language.short].rtl
          ? transform.convertMoney(amount, true)
          : transform.convertMoney(amount)
        : "",
    });
  };

  useEffect(() => {
    amountFunc2();
  }, [language]);

  return (
    <LayoutMain>
      <div className="w-full">
        <div>
          <h3 className="text-center bg-red my-8">
            {languages[language.language.short].chargePage.s1}
          </h3>
          <select
            onChange={(e: any) => {
              setTransferType(e.target.value);
              setMSG("");
            }}
            value={transferType}
            className="max-w-md block focus:outline-none mx-auto w-11/12 bg-gray-400 text-white rounded p-1 my-4"
          >
            <option value="0">
              {languages[language.language.short].mainAccount}
            </option>
            {unitsList.map((item: any, index: any) => (
              <option key={index} value={item.altAccountMetaData.uniqueId}>
                {languages[language.language.short].account}{" "}
                {renderAccountUnit(item.altAccountMetaData.unit)}
              </option>
            ))}
          </select>
          <div className="bg-white border mt-8 border-gray-300 w-11/12 flex items-center ltr-direction text-blue-900 placeholder-gray-500 focus:placeholder-gray-600 text-sm rounded-lg focus:outline-none focus:ring focus:ring-blue-500 max-w-md mx-auto radious">
            <div className="p-2">
              <FaMoneyBill1 className="text-2xl" />
            </div>
            <input
              autoComplete="off"
              onChange={amountFunc}
              placeholder={`${
                languages[language.language.short].amount
              } ${transferUnitPersian}`}
              value={state.filledAmount}
              className={
                "bg-white border-0 border-gray-300 w-full p-1 block outline-none rounded text-center text-gray-500 ltr-direction"
              }
            />
          </div>
          <p
            className={
              state.filledAmount
                ? "text-center w-full mt-3 text-gray-600 text-sm"
                : "hidden"
            }
          >
            {languages[language.language.short].chargePage.s11}:{" "}
            {state.filledAmount} {languages[language.language.short].toman}
          </p>
          <textarea
            className="bg-white border border-gray-300 w-11/12 outline-none max-w-md mx-auto rounded h-20 block mt-8 resize-none p-2 text-sm"
            placeholder={
              languages[language.language.short]
                .insertTransactionDescriptionHere
            }
            onChange={(e) =>
              setState({ ...state, description: e.target.value })
            }
            value={state.description}
          ></textarea>
          <div className="mt-8"></div>
          <div className="clear-both mt-8 text-center">{msg}</div>
          <button
            type="button"
            onClick={goBank}
            className="border border-teal-500  bg-teal-400 hover:bg-teal-500 text-white rounded-lg px-5 py-2.5 w-11/12 text-base max-w-md  block mx-auto"
          >
            {languages[language.language.short].chargePage.s2}
          </button>
          <div className="clear-both mt-8 text-center">
            <div dangerouslySetInnerHTML={{ __html: showBankPhysical.msg }} />
          </div>
          <button
            type="button"
            onClick={goBankPhysical}
            className={
              user.roles.includes("CAN_CASH_CHARGE")
                ? "border border-teal-500  bg-teal-400 hover:bg-teal-500 text-white rounded-lg px-5 py-2.5 w-11/12 text-base max-w-md mt-4 block mx-auto"
                : "hidden"
            }
          >
            {languages[language.language.short].chargePage.s3}
          </button>
          <div className="h-60 w-full"> </div>
        </div>
      </div>
    </LayoutMain>
  );
}
