import { useSelector } from "react-redux";
import profile from "../../themes/blue/images/profile.jpg";
import languages from "../../languages/languages";
import PersianTransform from "../../utils/persianTransform";
import { transform } from "../../utils/persianTransform";
import validator from "validator";

export default function Transaction({ tran }: any) {
  const persian = new PersianTransform();
  const language = useSelector((state: any) => state.language);

  let setColor = "border bg-green-100 border-green-300";

  if (tran.persianType.includes("برداشت")) {
    setColor = "border bg-red-100 border-red-300";
  }

  const rednerSwitch = (param: any, lang: string) => {
    console.log(param);
    let str = "";
    if (param.persianType.includes("واریز")) {
      if (param.sourceUsername.includes("خزانه کل")) {
        str = languages[lang].chargeAccount;
      } else if (param.sourceUsername.includes("خزانه برداشت")) {
        str = languages[lang].refund;
      } else {
        if (!validator.isEmail(param.sourceUsername) && languages[lang].rtl) {
          param.sourceUsername = persian.engNumberToPersian(
            param.sourceUsername
          );
        }

        str = languages[lang].transferFrom + " " + param.sourceUsername;
      }
    }

    if (param.persianType.includes("برداشت")) {
      if (param.destinationUsername.includes("خزانه برداشت")) {
        str = languages[lang].withDrawFromAccount;
      } else {
        if (
          !validator.isEmail(param.destinationUsername) &&
          languages[lang].rtl
        ) {
          param.destinationUsername = persian.engNumberToPersian(
            param.destinationUsername
          );
        }

        str = languages[lang].transferTo + " " + param.destinationUsername;
      }
    }

    return str;
  };

  function convertUnit(unit: any, lang: any) {
    console.log("#########################");
    console.log(unit);
    console.log("#########################");
    let myUnit = languages[lang].toman;
    switch (unit) {
      case "دلار":
        myUnit = languages[lang].dollar;
        break;
      case "درهم":
        myUnit = languages[lang].dirham;
        break;
      case "یورو":
        myUnit = languages[lang].euro;
        break;
    }
    return myUnit;
  }

  return (
    <div
      className={"rounded-lg  w-11/12 mx-auto mt-16  hover:shadow " + setColor}
    >
      <div className="w-full flex justify-center relative">
        <div className="absolute -top-10 w-14 h-14  ">
          <img
            className="rounded-full  border border-gray-100"
            src={
              tran.profileAvatarUrl
                ? `https://moneasy.ir/portal/external/Avatar/${tran.profileAvatarUrl}`
                : profile
            }
            alt="img"
          />
        </div>
      </div>
      <div className="flex justify-between items-center border-b border-b-gray-400">
        <div className="name p-2 text-sm w-1/2 ">
          <span className="w-full block  whitespace-nowrap text-ellipsis overflow-hidden">
            {rednerSwitch(tran, language.language.short)}
          </span>
        </div>
        <div className="text-left  p-2  w-1/2 whitespace-nowrap text-ellipsis overflow-hidden">
          {tran.when}
        </div>
      </div>
      <div className="px-2">
        <div className="flex justify-between items-center">
          <div className="name whitespace-nowrap p-2 ">
            {languages[language.language.short].amount}
          </div>
          <div className="border-b border-b-green-500 border-dashed w-full"></div>
          <div className="text-left whitespace-nowrap p-2">
            {language.language.rtl
              ? transform.convertMoney(tran.amount, true)
              : transform.convertMoney(tran.amount)}{" "}
            {convertUnit(tran.unit, language.language.short)}
          </div>
        </div>
        <div className="flex justify-between items-center">
          <div className="name whitespace-nowrap p-2 ">
            {languages[language.language.short].remaining}
          </div>
          <div className="border-b border-b-green-500 border-dashed w-full"></div>
          <div className="text-left whitespace-nowrap p-2">
            {language.language.rtl
              ? transform.convertMoney(tran.balance, true)
              : transform.convertMoney(tran.amount)}{" "}
            {convertUnit(tran.unit, language.language.short)}
          </div>
        </div>
      </div>
      <div className="text-sm whitespace-nowrap text-ellipsis w-full overflow-hidden border border-t-slate-400 p-1 flex gap-1">
        <div>{languages[language.language.short].description}: </div>
        <div className="text-ellipsis  overflow-hidden ">
          {tran.description === "null" ? "" : tran.description}
        </div>
      </div>
    </div>
  );
}
