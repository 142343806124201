import { FaBars, FaLanguage, FaRegUser } from "react-icons/fa6";
import { FaSignOutAlt, FaTimes } from "react-icons/fa";
import { transform } from "../utils/persianTransform";

import { useDispatch, useSelector } from "react-redux";
import { changeLanguage } from "../redux/languageSlice";
import languages from "../languages/languages";

import logo from "../themes/blue/images/logo-text.png";

import { FaArrowRightArrowLeft, FaMinus, FaPlus } from "react-icons/fa6";

import axios from "axios";
import { useEffect, useState } from "react";

import { Link, useLocation, useNavigate } from "react-router-dom";
import SideMenu from "./SideMenu";
import { changeSideMenuState } from "../redux/sideMenuSlice";

export default function LayoutMain({ children }: any) {
  const location = useLocation();

  const dispatch = useDispatch();

  const openLanguagesBox = (e: any) => {
    e.currentTarget.nextElementSibling.nextElementSibling.classList.toggle(
      "hidden"
    );
  };

  const changeLanguageFunc = (e: any, lng: any) => {
    e.currentTarget.parentElement.classList.toggle("hidden");
    dispatch(
      changeLanguage({
        short: lng,
        rtl: !!languages[lng].rtl,
      })
    );
  };

  const language = useSelector((state: any) => state.language);
  const [user, setUser] = useState({
    mainAccount: {
      balance: "0",
    },
  });

  const fetchData = async () => {
    try {
      let userRes: any = await axios.get("/api/current/user");

      setUser(userRes.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const navigate = useNavigate();

  const logout = async () => {
    try {
      await axios.get("/api/logout");
      navigate("/");
    } catch (error) {
      console.log("error layout main");
    }
  };

  useEffect(() => {
    (async function () {
      try {
        await axios.get("/api/current/user");
      } catch (error) {
        return navigate("/");
      }
    })();
  }, [location]);

  return (
    <div
      className={language.language.rtl ? "rtl-direction relative" : "relative"}
    >
      <SideMenu />
      <div className="flex items-center justify-between bg-teal-500 p-2 sticky top-0 z-10">
        <FaBars
          onClick={() => {
            dispatch(changeSideMenuState("open"));
            console.log("hello");
          }}
          className="text-2xl cursor-pointer text-white"
        />
        <div className="cursor-pointer">
          <Link to="/home">
            <img src={logo} alt="مانیزی" className="cursor-pointer" />
          </Link>
        </div>
        <div className="flex text-2xl  items-center text-white relative">
          <FaLanguage
            className="m-1 cursor-pointer"
            onClick={openLanguagesBox}
          />
          <FaSignOutAlt className="m-1 cursor-pointer" onClick={logout} />

          <div
            className={
              language.language.rtl
                ? "absolute bg-gray-600 top-6 left-9 text-lg text-center flex flex-col p-2 rounded-lg hidden"
                : "absolute bg-gray-600 top-6 right-9 text-lg text-center flex flex-col p-2 rounded-lg hidden"
            }
          >
            {Object.values(languages).map((lng: any) => (
              <span
                className="block cursor-pointer text-gray-200 hover:text-white"
                onClick={(e) => changeLanguageFunc(e, lng["short"])}
              >
                {lng["language"]}
              </span>
            ))}
          </div>
        </div>
      </div>
      {children}
      <div className="clear-both"></div>
      <div className="fixed bottom-0 right-0 left-0">
        <div className="flex justify-center flex-col w-full bg-gray-100 max-w-2xl mx-auto relative">
          <div
            className={
              location.pathname.substring(
                location.pathname.lastIndexOf("/") + 1
              ) === "home"
                ? "flex mx-auto justify-around gap-y-3 gap-x-16 p-2 W-full"
                : "hidden"
            }
          >
            <div className="text-center ">
              <Link to="/transaction/charge" className="block w-full">
                <div className="w-16 h-16 rounded-full bg-teal-500  flex items-center justify-center text-4xl">
                  <FaPlus className="text-white" />
                </div>
                <span className="block text-center mt-2 text-gray-500">
                  {languages[language.language.short].charge}
                </span>
              </Link>
            </div>
            <div className="text-center ">
              <Link to="/transaction/transfer" className="block">
                <div className="w-16 h-16 rounded-full bg-teal-500  flex items-center justify-center  text-4xl">
                  <FaArrowRightArrowLeft className="text-white" />
                </div>
                <span className="block text-center mt-2 text-gray-500">
                  {languages[language.language.short].transfer}
                </span>
              </Link>
            </div>
            <div className="text-center">
              <Link to="/transaction/withdraw" className="block">
                <div className="w-16 h-16 rounded-full bg-teal-500  flex items-center justify-center  text-4xl">
                  <FaMinus className="text-white" />
                </div>
                <span className="block text-center mt-2 text-gray-500">
                  {languages[language.language.short].withdraw}
                </span>
              </Link>
            </div>
          </div>
          <div className="text-center py-1 bg-slate-400 text-white">
            {languages[language.language.short].balance}:{" "}
            {language.language.rtl
              ? transform.convertMoney(user.mainAccount.balance, true)
              : transform.convertMoney(user.mainAccount.balance)}{" "}
            {languages[language.language.short].toman}
          </div>
        </div>
      </div>
    </div>
  );
}
